@import '../../assets/css/variables.scss';

.container {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    @media screen and (max-width: $medium-screen) {
        padding: 0 16px 0 16px;
    }
    @media screen and (min-width: $medium-screen) and (max-width: $large-screen) {
        padding: 0 32px 0 32px;
    }
    @media screen and (min-width: $large-screen) and (max-width: $xxl-large-screen) {
        padding: 0 60px 0 60px;
    }
    @media screen and (min-width: $xxl-large-screen) {
        padding: 0 200px 0 200px;
    }
}

.screen {
    display: flex;
    justify-content: center;
    @media screen and (max-width: $large-screen) {
        flex-direction: column-reverse;
        gap: 16px;
    }
    @media screen and (min-width: $large-screen) {
        flex-direction: row;
        gap: 48px;
    }
}

.leftScreen {
    display: flex;
    flex-direction: column;
    gap: 16px;
    @media screen and (max-width: $large-screen) {
        width: 100%;
    }
    @media screen and (min-width: $large-screen) {
        width: 67%;
    }
}

.rightScreen {
    @media screen and (max-width: $large-screen) {
        width: 100%;
    }
    @media screen and (min-width: $large-screen) {
        width: 33%;
    }
}

.titleWrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    @media screen and (max-width: $large-screen) {
        padding: 40px 0 40px 0;
    }
    @media screen and (min-width: $large-screen) {
        padding: 56px 0 56px 0;
    }
}

.title {
    font-family: $font-family-lato;
    font-weight: 700;
    line-height: 37.5px;
    color: #242222;
    @media screen and (max-width: $medium-screen) {
        font-size: 32px;
    }
    @media screen and (min-width: $medium-screen) {
        font-size: 36px;
    }
}

.desc {
    font-family: $font-family-lato;
    font-weight: 400;
    line-height: 28px;
    color: #111827BF;
    @media screen and (max-width: $medium-screen) {
        font-size: 18px;
    }
    @media screen and (min-width: $medium-screen) {
        font-size: 20px;
    }
}
