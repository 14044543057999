@import '../../../assets/css/variables.scss';

.container {
    display: flex;
    align-items: center;
    gap: 12px;
}

.text {
    display: flex;
    align-items: center;
    font-family: $font-family-lato;
    font-size: 16px;
    line-height: 22px;
    text-align: left;
    font-weight: 500;
    color: #242222;
}

.label {
    margin-bottom: 2px;
}