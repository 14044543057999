@import '../../../assets/css/variables';

.header {
    display: flex;
    justify-content: space-between;
}

.headerTxt {
    font-size: 20px;
    font-weight: 700;
    color: #1F1F1F;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.contentDesc {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    color: #1F1F1F;
}

.contentBox {
    display: flex;
    align-items: center;
    padding: 16px 20px 20px 20px;
    border: 1px solid #D9D9D9;
    border-radius: 8px;

    @media screen and (max-width: $small-screen) {
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
    }

}

.contentBoxTitle {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
}

.contentBoxDesc {
    color: #808080;
    font-size: 16px;
    font-weight: 500;
    margin-top: 4px;
}

.icon {
    cursor: pointer;
}

.lostCardText {
    font-size: 14px;
    font-weight: 600;
    color: #808080;
    text-align: center;
    padding: 12px 24px 12px 16px;
    margin-top: 8px;
    cursor: pointer;
}