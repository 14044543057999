@import '../../assets/css/variables';

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 65%;
    flex-direction: column;
    gap: 12px;
    margin: 48px 0 48px 0;
    align-self: center;
}

.text {
    font-family: $font-family-lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: #464444;
}