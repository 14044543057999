@import '../../../assets/css/variables.scss';


.settingWrapperSignIn {
    flex-direction: column !important;
    gap: 2px !important;
    border-top: none !important;
}

.settingWrapper {
    display: flex;
    gap: 8px;
    border-top: 1px solid #F3F4F6;
    cursor: pointer;
}

.text {
    font-family: $font-family-lato;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #242222;
}

.subtext {
    font-family: $font-family-lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #808080;
}

.iconWrapper {
    display: flex;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
}