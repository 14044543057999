@import '../../assets/css/variables.scss';

.container {
    display: flex;
    justify-content: center;
    border-radius: 16px;
    gap: 15px;

    @media screen and (max-width: $large-screen) {
        padding: 20px 16px;
    }

    @media screen and (min-width: $large-screen) {
        padding: 40px 130px;
    }

    @media screen and (max-width: $small-screen) {
        flex-direction: column;
    }

    @media screen and (min-width: $small-screen) {
        flex-direction: row;
    }
}

.leftContainer {
    @media screen and (max-width: $small-screen) {
        align-self: center;
        width: 90%;
    }
    @media screen and (min-width: $small-screen) and (max-width: $medium-screen)  {
        width: 40%;
    }
    @media screen and (min-width: $medium-screen) {
        width: 30%;
    }
}

.rightContainer {
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    gap: 10px;

    @media screen and (max-width: $small-screen) {
        width: 100%;
    }
    @media screen and (min-width: $small-screen) and (max-width: $medium-screen)  {
        width: 60%;
    }
    @media screen and (min-width: $medium-screen) {
        width: 65%;
    }
}

.wallet {
    display: flex;
    justify-content: center;
    margin-top: 16px;
    cursor: pointer;
}

.sticky {
    margin-top: 143px;
}

.deviceSelector {
    width: 100px;
}

.deviceSwitcher {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 16px;
}