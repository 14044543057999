@import '../../../assets/css/variables.scss';

.box {
    display: flex;
    width: auto;
    height: auto;
    padding: 24px;
    gap: 16px;
    border-radius: 8px;
    justify-content: space-between;
    border: 1px solid #D9D9D9;
    text-align: center;

    @media screen and (max-width: $small-screen) {
        flex-direction: column;
        align-items: flex-start;
    }

    @media screen and (min-width: $small-screen) {
        align-items: center;
    }
}

.salesforceBox {
    display: flex;
    gap: 16px;
}

.connectBtn {
    align-self: flex-start;
}

.salesforceIcon {
    align-self: flex-start;
}

.textWrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 4px;
    text-align: left;
}

.title {
    font-size: 16px;
    font-weight: 600;
    color: #111827;
}

.desc {
    font-size: 14px;
    font-weight: 400;
    color: #6B7280;
}

.connected {
    padding: 5px 10px 5px 10px;
    border-radius: 34px;
    background-color: #D1FAE5;
    font-family: $font-family-lato;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    color: #065F46;
    margin-top: 6px;
}

.downloadWrapper {
    display: flex;
    height: 30px;
    align-items: center;
    gap: 8px;
    margin-top: 2px;
}

.downloadText {
    font-size: 14px;
    font-weight: 400;
    text-decoration-line: underline;
    text-decoration-style: solid;
    color: #000000;
    cursor: pointer;
}

.text {
    font-family: $font-family-lato;
    line-height: 20px;
}

.btnView {
    background-color: #242222 !important;
    height: 48px;
    width: max-content !important;

    div {
        color: #FFFFFF
    }
}

.btn {
    background-color: #FFFFFF !important;
    width: max-content !important;

    div {
        background-color: #FFFFFF !important;
        color: #242222
    }

    @media screen and (max-width: $small-screen) {
        width: 100%
    }
}
