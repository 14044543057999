@import '../../../assets/css/variables';

.order {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 288px;
    width: 100%;
    border-radius: 16px;
    border: 1px dashed #111827;
    gap: 16px;
    padding: 12px 24px;
    box-sizing: border-box;

    @media screen and (max-width: $small-screen) {
        display: none;
    }
    @media screen and (min-width: $small-screen) and (max-width: $medium-screen) {
        max-width: 258px;
    }
}

.btn {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border: 2px solid #000000;
    border-radius: 4px;
    cursor: pointer;
}

.plusVertical {
    position: absolute;
    height: 50%;
    border: 1px solid #000000;
}

.plusHorizontal {
    position: absolute;
    width: 50%;
    border: 1px solid #000000;
}

.text {
    font-family: $font-family-lato;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0;
    text-align: center;
}