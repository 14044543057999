.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 282px;
    min-width: 170px;
    gap: 8px;
    background-color: #F0F0F0;
}

.tabWrapper {
    position: relative;
}

.tab {
    display: flex;
    width: 100%;
    padding: 12px;
    border-radius: 8px;
    box-sizing: border-box;
    cursor: pointer;
    line-height: 24px;
}

.tabSelected {
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px 0 #00000014;
}

.redLine {
    position: absolute;
    top: 46%;
    bottom: 54%;
    left: -7px;
    width: 18px;
    height: 4px;
    border-radius: 2px 2px 0 0;
    rotate: 90deg;
    background-color: #ED1B24;
}