.container {
    width: -webkit-fill-available;
}

.inputsContainer {
    display: flex;
    gap: 6px;
}

.colorBox {
    display: flex;
    gap: 7px;
    border: 1px solid #CDCDCD;
    background-color: white;
    padding: 8px 9px;
    border-radius: 6px;
    height: 23px;
    margin-top: 22px;
}

.colorInput {
    height: 30px;
    width: 30px;
    background-color: transparent;
    border: transparent;
    margin-top: -3px;
    display: flex;
}