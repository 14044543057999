.container {
  position: relative;
  background-color: white;
  border-radius: 16px;
  min-height: 433px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.blankCover {
  width: 100%;
  height: 150px;
  object-fit: cover;
  background-color: #cdcdcd;
  border-radius: 16px 16px 0 0;
}

.coverContainer {
  width: 100%;
}

.coverImage {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 16px 16px 0 0;
}

.profileImage {
  position: absolute;
  width: 130px;
  height: 130px;
  border-radius: 50%;
}

.imageWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.profilePhoto {
  width: 100%;
  height: 130px;
  border-radius: 130px
}

.coverPhoto {
  display: flex;
  width: 100%;
  height: 150px;
  align-items: center;
  justify-content: center;
}

.cover {
  height: 150px;
  width: 100%;
  border-radius: 16px 16px 0 0;
}

.profilePhotoWrapper {
  width: 130px;
  height: 130px;
  background: rgb(255, 255, 255) 50% / cover no-repeat;
  border-radius: 104px;
  margin-top: -65px;
  z-index: 10;
  border: 1px solid lightgrey;
}

.noProfilePhotoWrapper {
  width: 100%;
  height: 35px;
  background-color: inherit;
}