@import '../../../assets/css/variables';

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    padding: 24px;
    gap: 4px;
    border-radius: 8px;
    background: #FFFFFF;
    box-shadow: 0 1px 2px 0 #0000000F;
    box-sizing: border-box;
}

.text {
    font-family: $font-family-lato;
    letter-spacing: 0;
}

.title {
    font-family: $font-family-lato;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: #6B7280;
}

.stats {
    font-size: 30px;
    font-weight: 600;
    line-height: 36px;
    color: #111827;
}

.increaseText {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #6B7280;
}

.increaseContainer {
    display: flex;

    @media screen and (max-width: $small-screen) {
        align-items: center;
        gap: 8px;
    }

    @media screen and (min-width: $small-screen) and (max-width: $large-screen) {
        flex-direction: column;
        align-items: flex-start;
    }

    @media screen and (min-width: $large-screen) {
        align-items: center;
        gap: 8px;
    }
}

.increaseWrapper {
    display: flex;
    align-self: flex-start;
    align-items: center;
    gap: 4px;
}

.increaseNumber {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    color: #059669;
}