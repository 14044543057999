.contactIconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
}

.contactText {
    color: #242222;
    margin: 0 0 4px 0;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
}

.contactTextDetails {
    font-weight: 400;
}

.contactWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    width: 80%;
    padding: 10px 0;
}

.aboutContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    width: 80%;
    padding: 20px 0;
    border-top: 1px solid #D9D9D9;
    margin-top: 20px;
  }

.logoWrapper {
    display: flex;
    width: 40px;
    min-width: 40px;
    height: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 40px;
    background: #242222;
}

.contact {
    display: flex;
    gap: 12px;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.pointer {
    cursor: pointer;
}
