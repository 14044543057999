@import '../../../assets/css/variables';

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    @media screen and (max-width: $medium-screen) {
        gap: 24px;
    }
}

.topSection {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100px;
    margin-top: 20px;
    align-items: flex-start;
    @media screen and (max-width: $medium-screen) {
        flex-direction: column;
    }
}

.orderCard {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-end;
    @media screen and (max-width: $medium-screen) {
        justify-content: flex-start;
    }
}

.headerBtn {
    padding: 12px 24px 12px 24px !important;
}

.text {
    width: 100%;
    font-family: $font-family-lato;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0;
    text-align: left;
    display: flex;
    flex: 1;
    color: #242222;
    text-wrap-mode: nowrap;

    @media screen and (max-width: $medium-screen) {
        font-size: 32px;
    }
    @media screen and (min-width: $medium-screen) {
        font-size: 36px;
    }
}

.search {
    display: flex;
    flex: 1;
    width: 100%;
    @media screen and (max-width: $medium-screen) {
        flex-direction: column;
        gap: 12px;
    }
    @media screen and (min-width: $medium-screen) {
        margin-left: auto;
        gap: 24px;
    }
}

.filterContainer {
    display: flex;

    @media screen and (max-width: $medium-screen) {
        flex-direction: column;
        gap: 8px;
    }
    @media screen and (min-width: $medium-screen) {
        margin-left: auto;
        flex-direction: row-reverse;
        gap: 16px;
    }
}

.filterAndView {
    display: flex;
    @media screen and (max-width: $medium-screen) {
        gap: 4px;
    }
    @media screen and (min-width: $medium-screen) {
        gap: 12px;
    }
}

.selectorWrapper {
    @media screen and (max-width: $medium-screen) {
        width: 50%;
    }
    @media screen and (min-width: $medium-screen) {
        width: 120px;
    }
}

.selector {
    flex: 1;
    @media screen and (max-width: $medium-screen) {
        width: 100%;
    }
    @media screen and (min-width: $medium-screen) {
        width: 120px;
    }
}

.input {
    display: flex;
    justify-content: flex-start;
    flex: 2;
    input {
        width: 100%;
        @media screen and (min-width: $medium-screen) {
            max-width: 320px;
        }
    }
}

.btn {
    background-color: #242222 !important;
    height: 48px;
    width: max-content !important;

    div {
        color: #FFFFFF
    }
}