@import '../../assets/css/variables';

.container {
    display: flex;
    flex-direction: row;
    height: 100vh;
    padding: 0 5% 0 5%;
    gap: 16px;
    background-color: #FFFFFF;
    @media screen and (max-width: $large-screen) {
        flex-direction: column-reverse;
        justify-content: flex-end;
    }
    @media screen and (min-width: $large-screen) {
        flex-direction: row;
    }
}

.leftHalf,
.rightHalf {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: white;
    padding: 24px;
    @media screen and (max-width: $large-screen) {
        padding: 0;
    }
}

.leftHalf {
    flex: 1;
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
    @media screen and (max-width: $large-screen) {
        justify-content: flex-start;
        align-items: center;
    }
}

.rightHalf {
    @media screen and (min-width: $large-screen) {
        flex: 1;
    }
    @media screen and (max-width: $large-screen) {
        height: 350px;
    }
}

.leftHalfContainer {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    gap: 48px;
    @media screen and (max-width: $large-screen) {
        flex: 1;
    }
}

.notFoundTextContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
}


.notFoundImage {
    height: auto;

    @media screen and (max-width: $large-screen) {
        width: fit-content;
        max-width: 300px;
    }
    @media screen and (min-width: $large-screen) and (max-width: $xxl-large-screen)  {
        width: 400px;
    }
    @media screen and (min-width: $xxl-large-screen) {
        width: 600px;
    }
}

.title {
    font-family: $font-family-saira-semibold;
    font-weight: 700;
    text-align: left;
    color: #121416;
    @media screen and (max-width: $large-screen) {
        display: flex;
        font-size: 36px;
        line-height: 30px;
        text-align: center;
        justify-content: center;
        align-items: center;
    }
    @media screen and (min-width: $large-screen) {
        font-size: 56px;
        line-height: 67px;
    }
}

.desc {
    font-family: $font-family-saira;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    color: #121416;
    @media screen and (max-width: $large-screen) {
        display: flex;
        font-size: 18px;
        text-align: center;
    }
    @media screen and (min-width: $large-screen) {
        font-size: 20px;
    }
}

.btn {
    background-color: #242222 !important;
    height: 48px;
    width: max-content !important;
    @media screen and (max-width: $large-screen) {
        align-self: center;
    }
    @media screen and (min-width: $large-screen) {
        align-self: flex-start;
    }

    div {
        font-family: $font-family-saira;
        color: #FFFFFF;
    }
}

.logo {
    margin-top: 48px;
    @media screen and (max-width: $large-screen) {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: flex-end;
        margin-bottom: 10%;
    }

    svg {
        @media screen and (max-width: $large-screen) {
            width: 150px;
            height: 48px;
        }
    }
}