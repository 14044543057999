@import '../../assets/css/variables';

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    margin: 0;
    box-sizing: border-box;

    @media screen and (min-width: $medium-screen) {
        background: #F0F0F0 url('../../assets/icons/background/activate-bg.svg') no-repeat center;
    }
}

.card {
    display: flex;
    flex-direction: column;
    max-width: 588px;
    height: 100%;
    gap: 16px;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    border: 1px solid #E9E9E9;
    background: #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.06), 0 0 4px 0 rgba(0, 0, 0, 0.04);

    @media screen and (max-width: $medium-screen) {
        padding: 24px;
    }
    @media screen and (min-width: $medium-screen) {
        padding: 48px 110px 48px 110px;
    }
}

.activateContainer {
    display: flex;
    flex-direction: column;
    color: #464444;
    font-weight: 400;
    gap: 16px;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 16px;
}

.logo {
    display: flex;
    width: 130px;
    height: 130px;
    border-radius: 130px;
    justify-content: center;
    border: 0.88px solid #F0F0F0;
    box-sizing: border-box;
    align-items: center;
}

.title {
    font-family: $font-family-lato;
    font-size: 24px;
    font-weight: 700;
    line-height: 20px;
    color: #242222;
}

.text, .textBottom {
    font-family: $font-family-lato;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #242222;
    text-align: center;
}

.textBottom {
    margin-top: -16px;
    margin-bottom: 40px;
}

.activateBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 48px;
    padding: 12px 32px 12px 32px;
    border-radius: 32px;
    box-shadow: 0 1px 2px 0 #0000000D;
    background-color: #242222;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
}

.link {
    display: flex;
    margin-top: 4px;
    margin-bottom: 4px;
    font-weight: 700 !important;
}