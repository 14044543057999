.containerAndroid, .containerIOS {
    position: relative;
    background-color: white;
    height: fit-content;
    display: flex;
    flex-direction: column;
}

.containerAndroid {
    border-radius: 26px;
}

.headerContainerAndroid, .headerContainerIOS {
    height: 52px;
    background-color: #FEFFFE;
    display: flex;
    align-items: center;
    gap: 16px;
    padding-left: 8px;
}

.headerContainerAndroid {
    border-radius: 16px 16px 0 0;
}

.coverContainerAndroid, .coverContainerIOS {
    width: 100%;
    position: relative;
}

.coverContainerAndroid, .coverContainerIOS > * {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}


.coverContainerAndroid {
    border-radius: 0 0 16px 16px;
}

.coverContainerIOS {
    padding-bottom: 32.56%; /* This maintains a 1032/336 ratio for iOS standard, calculated as (336/1032) * 100 */
}

.infosContainer {
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 8px;
}

.companyPositionWrapper {
    display: flex;
    justify-content: space-between;
    gap: 4px;
}

.infoContainer, .infoContainerPosition {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.infoContainerPosition {
    align-items: flex-end;
}

.infoContainer, .infoContainerPosition span {
    font-size: 9px;
    font-weight: 600;
}

.cover, .coverAndroid {
    width: 100%;
    height: auto;
}

.cover {
    position: absolute;
}

.coverAndroid {
    border-radius: 0 0 16px 16px;
}

.bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-bottom: 16px;
}

.qrcodeIOS, .qrcodeAndroid {
    width: 40%;
}

.qrcodeAndroidUuid {
    display: flex;
    justify-content: center;
    margin: 0 0 25px 0;
    font-size: 12px;
}

.qrcodeIOS {
    margin: 50px 0 0 0;
}

.qrcodeAndroid {
    display: flex;
    justify-content: center;
    margin: 25px 0 0 0;
}

.qrCodeImg {
    display: flex;
    border: 1px solid #F0F0F0;
    border-radius: 8px;
    padding: 12px;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF;
}

.companyLogoWrapperAndroid, .companyLogoWrapperIOS {
    width: 38px;
    height: 38px;
}

.companyLogoAndroid, .companyLogoIOS {
    width: 38px;
    height: 38px;
}

.companyLogoAndroid, .companyLogoWrapperAndroid {
    border-radius: 38px;
}

.company {
    font-size: 15px;
    font-weight: 600;
    color: #242222;
}

.info {
    font-size: 16px;
    font-weight: 300;
}

.dividerAndroid {
    width: 100%;
    height: 1px;
    margin-top: 4px;
    background-color: grey;
    opacity: 0.5;
}