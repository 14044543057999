@import '../../../assets/css/variables';

.header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}

.headerTxt {
    font-size: 20px;
    font-weight: 700;
    color: #1F1F1F;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.inputContainer input {
    height: 48px !important;
    box-sizing: border-box;
    gap: 4px;
}

.changeOwnerWrapper {
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: center;
    align-items: center;
}

.changeOwnerDesc {
    font-size: 12px;
    color: #DC2626;
}