@import '../../assets/css/variables.scss';

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;

    @media screen and (max-width: $medium-screen) {
        padding: 12px 16px 32px 16px;
        gap: 16px;
    }

    @media screen and (min-width: $medium-screen) and (max-width: $xxl-large-screen) {
        padding: 0 40px 0 40px;
    }

    @media screen and (min-width: $xxl-large-screen) {
        padding: 0 200px 0 200px;
    }
}

.title {
    width: 100%;

    @media screen and (max-width: $medium-screen) {
        padding: 32px 20px 0 20px;
        gap: 16px;
    }

    @media screen and (min-width: $medium-screen) and (max-width: $xl-large-screen) {
        padding: 40px 0 40px 0;
    }

    @media screen and (min-width: $xl-large-screen) {
        padding: 64px 0 64px 0;
    }
}

.screen {
    display: flex;
    width: 100%;
    gap: 16px;

    @media screen and (max-width: $large-screen) {
        flex-direction: column;
        padding: 0 16px 0 16px;
    }
}

.leftScreen {
    .selector {
        width: 100%;
    }

    flex: 1;

    @media screen and (max-width: $large-screen) {
        margin-bottom: 16px;
    }
}

.rightScreen {
    flex: 2;
}

.text {
    font-family: $font-family-lato;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0;
    text-align: left;

    @media screen and (max-width: $small-screen) {
        font-size: 32px;
    }

    @media screen and (min-width: $small-screen) {
        font-size: 36px;
    }
}