@import '../../../assets/css/variables.scss';

.chartWrapper {
    background-color: #FFFFFF;
    border-radius: 8px;
    box-shadow: 0 1px 2px 0 #0000000F;
}

.countriesChart {
    padding: 60px 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
}

.boxInfo {
    display: flex;
    flex-direction: column;
    width: 40%;
    padding: 10px;

    @media screen and (max-width: $large-screen) {
        width: 80%;
    }
}

.boxHeader {
    display: flex;
    justify-content: space-between;
    font-weight: 600;
}

.boxHeader div {
    color: #6B7280;
    border-bottom: 1px dashed #6B7280;
    padding-bottom: 5px;
}

.countryInfos {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 10px;
}

.countryInfo {
    display: flex;
    justify-content: space-between;
}

.leftChart {
    width: 50%;
}

.leftChart {
    @media screen and (max-width: $large-screen) {
        width: 100%;
    }
}

.chart {
    @media screen and (max-width: $small-screen) {
        height: 271px;
    }

    @media screen and (min-width: $small-screen) and (max-width: $large-screen) {
        height: 341px;
    }

    @media screen and (min-width: $large-screen) {
        height: 411px;
    }
}

.title {
    font-family: $font-family-lato;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    padding: 20px 24px 20px 24px;
}

.tick,
.line {
    stroke: none !important;
}

.thickLabel {
    @media screen and (max-width: $large-screen) {
        font-size: 10px !important;
    }

    @media screen and (min-width: $large-screen) {
        font-size: 12px !important;
    }
}