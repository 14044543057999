@import '../../assets/css/variables.scss';

.container {
    height: 28px;
    width: 44px;
    border-right: 1px solid gray;
    cursor: initial;
    z-index: 1001;

    @media screen and (max-width : $medium-screen) {
        width: 36px;
    }
    @media screen and (min-width : $medium-screen) {
        margin-right: 6px;
    }
}

.iconWrapper {
    position: relative;
    cursor: pointer;
}

.counter, .bigCounter {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    height: 16px;
    border-radius: 20px;
    background-color: #ED1B24;
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 900;
    left: 12px;
}

.counter {
    width: 16px;
}

.bigCounter {
    width: 19px;
}

.box {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 384px;
    padding: 16px 8px 16px 8px;
    gap: 16px;
    border-radius: 6px;
    box-shadow: 0 0 0 1px #E5E8EF;
    background-color :#FFFFFF;
    z-index: 202;
    right: 356px;
    box-sizing: border-box;


    @media screen and (max-width : $small-screen) {
        width: 324px;
    }
    @media screen and (max-width : $medium-screen) {
        right: 206px;
    }
}

.mobileBox {
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 343px;
    padding: 16px 8px 16px 8px;
    gap: 16px;
    border-radius: 6px;
    box-shadow: 0 0 0 1px #E5E8EF;
    background-color :#FFFFFF;
    z-index: 202;
    right: 356px;
    box-sizing: border-box;
    top: 80px;
    left: 50%;
    transform: translate(-50%, 0%);
}

.notificationsWrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-height: 600px;
    overflow: auto;
}

.boxHeader {
    display: flex;
    height: 24px;
    padding: 0 8px 0 8px;
    justify-content: space-between;
}

.boxNotification {
    font-family: $font-family-lato;
    color: #242222;
    font-size: 16px;
    font-weight: 800;
    line-height: 24px;
    text-align: left;
}

.boxMarkRead {
    font-family: $font-family-lato;
    color: #464444;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    text-decoration: underline;
    cursor: pointer;
}

.boxViewAll {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    padding: 11px 17px 11px 17px;
    border-radius: 6px;
    border: 1px solid #D1D5DB;
    font-family: $font-family-lato;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #121111;
    box-sizing: border-box;
    margin: 0 8px 0 8px;
    cursor: pointer;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); 
    z-index: 1000;
}