@import '../../assets/css/variables.scss';

.container {
    display: flex;
    flex-direction: column;
    @media screen and (max-width: $large-screen) {
        padding: 20px 16px;
    }
    @media screen and (min-width: $large-screen) {
        padding: 40px 130px;
    }
}

.dividerWrapper {
    margin: 23px 0 16px 0;
}

.cardBox {
    display: flex;
    flex-wrap: wrap;
    border-radius: 16px;
    gap: 12px;
    justify-content: center;
}

.pagination {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
}

.emptyCard {
    display: flex;
    justify-content: center;
}

.noIcardsFound {
    display: flex;
    justify-content: center;
    font-weight: 500;
    font-size: 18px;
    font-family: $font-family-lato;
    margin-top: 48px;
}

.loading {
    align-self: center;
    margin-top: 100px;
    margin-bottom: 120px;
}