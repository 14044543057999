@import '../../../assets/css/variables.scss';

.contents {
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: flex-start;
    align-items: flex-start;
    border: 1px solid #E5E7EB;
    border-radius: 8px;
    padding: 32px;
    background-color: #FFFFFF;
}

.contentsWrapper {
    display: flex;
    position: relative;
    gap: 4px;
}

.contentsTitle {
    font-family: $font-family-lato;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 16px;
    align-self: flex-start;
}

.text {
    font-family: $font-family-lato;
    font-weight: 700;
    line-height: 24px;
    @media screen and (max-width: $medium-screen) {
        font-size: 16px;
    }
    @media screen and (min-width: $medium-screen) {
        font-size: 18px;
    }
}

.version {
    color: #242222;
}

.date {
    color: #24222280;
}

.redLine {
    position: absolute;
    top: 48%;
    left: -39px;
    width: 18px;
    height: 4px;
    border-radius: 2px 2px 0 0;
    rotate: 90deg;
    background-color: #ED1B24;
}