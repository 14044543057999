@import '../../assets/css/variables.scss';

.container {
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 12px 16px 12px 16px;
    border-radius: 6px;
    cursor: pointer;
}

.read {
    background-color: #FFFFFF;
}

.unread {
    background-color: #F0F0F0;
}

.title, .body {
    font-family: $font-family-lato;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
}

.title {
    font-weight: 700;
    color: #121111;
}

.body {
    font-weight: 400;
    color: #224444;
}

.timesAgoWrapper {
    display: flex;
    gap: 8px;
    align-items: center;
}

.timesAgoIndicator {
    width: 6px;
    height: 6px;
    border-radius: 24px;
    background-color: #ED1B24;
}

.timesAgo {
    font-family: $font-family-lato;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #224444;
}