@import "../../../assets/css/variables.scss";

.container {
  background-color: white;
  padding: 0 20px;
  border-radius: 16px;
}

.form {
  display: flex;
  flex-direction: column;
  border-top: 1px solid #d9d9d9;
  padding: 20px 0;
  gap: 24px;
}

.colorsContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
}

.colorContainer {
  width: 100px;
  padding: 12px 13px;
  border: 1px solid #cdcdcd;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
}

.colorContainer:focus {
  border: 1px solid #242222;
  background-color: #F8F8F8;
}

.default {
  background-color: #242222;
}

.color {
  width: 24px;
  height: 24px;
  border-radius: 20px;
}

.redColor {
  background-color: #d52739;
}

.yellowColor {
  background-color: #fa9c3c;
}

.greenColor {
  background-color: #62b138;
}

.purpleColor {
  background-color: #6946bb;
}

.customColor {
  background: linear-gradient(135deg,
      #ff5c0d 0%,
      #e8b419 17%,
      #5fdb3a 36.5%,
      #0cd2d4 55.5%,
      #1737ed 76%,
      #ff00af 100%);
}

.dirty {
  box-shadow: 
    0 0 8px 4px #DBEAFE,
    0 1px 3px 0 #0000001A,
    0 4px 4px 0 #00000040;
  border: 1px solid #93C5FD;
}