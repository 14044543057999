@import '../../../assets/css/variables';

.header {
    display: flex;
    justify-content: space-between;
}

.headerTxt {
    font-size: 20px;
    font-weight: 700;
    color: #1F1F1F;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.selector {
    width: 100px !important;
}

.label {
    font-size: 15px;
    font-weight: 500;
    align-self: flex-start;
    color: #464444;
    line-height: 20px;
}

.selectorWrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.inputContainer input {
    height: 48px !important;
    box-sizing: border-box;
}