@import '../../../assets/css/variables.scss';

.container {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    z-index: 1001;
    background-color: #00000040;
}

.modal {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 512px;
    border-radius: 12px;
    gap: 16px;
    box-shadow: 0 10px 10px -5px #0000000A;
    background-color: #FFFFFF;
    margin: 16px;

    @media screen and (max-width: $small-screen) {
        padding: 25px;
        width: 300px;
    }

    @media screen and (min-width: $small-screen) {
        padding: 25px;
    }
}

.header {
    display: flex;
    justify-content: space-between;
}

.headerTxt {
    font-size: 20px;
    font-weight: 700;
    color: #1F1F1F;
}

.boxWrapper, .boxLinkWrapper {
    border: 1px solid #D9D9D9;
    border-radius: 6px;
    padding: 12px 16px 12px 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
}

.boxWrapper {
    @media screen and (max-width: $small-screen) {
        flex-direction: column;
        text-align: center;
    }
}

.left {
    display: flex;
    align-items: center;
    gap: 8px;
}

.rightText {
    font-size: 16px;
    font-weight: 500;
    color: #1B1A1A;
    cursor: pointer;
}

.downloadText {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    cursor: pointer;
    @media screen and (max-width: $small-screen) {
        margin-top: 16px
    }
}

.qrCodeImg {
    width: 66px;
    height: 66px;
}

.qrCodeTxt {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.qrCodeTitleTxt {
    font-size: 16px;
    font-weight: 700;
    color: #242222;
}
