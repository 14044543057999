@import '../../../assets/css/variables.scss';

.form {
    background-color: #FFFFFF;
    border-radius: 6px;
    width: 184px;
}

.select {
    height: 48px;
    padding: 9px 13px 9px 13px;
    border-radius: 6px;
    gap: 4px;

    div {
        display: flex;
        padding: 0 16px 0 0 !important;
        gap: 8px;
    }
}

.label {
    font-family: $font-family-lato !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    letter-spacing: 0 !important;
    top: 2px !important;
    color: #808080 !important;
}

.selectorWrapper {
    .webSelector {
        width: 60px;
    }
}

.selector {
    @media screen and (max-width: $small-screen ) {
        margin: 0 !important;
    }

    @media screen and (min-width: $small-screen ) {
        margin: 0 16px 0 0 !important;
    }
}

.selector {
    width: 100%;
}

.mobileSelectorWrapper {
    display: flex;
    justify-content: center;

    .mobileSelector {
        width: 94%;
        margin-top: 10px;
    }
}

.selectorCompany,
.activeCompany {
    width: fit-content !important;
    margin-right: auto !important;
}

.activeCompany {
    margin-left: 14px;
    text-overflow: ellipsis;
    text-wrap: nowrap;
    overflow: hidden;
}

.switcher {
    font-family: $font-family-lato;
    font-size: 12px;
    cursor: pointer;
    margin-left: 14px;
    width: fit-content !important;
    margin-right: auto !important;
}

.mobileSwitcher {
    font-family: $font-family-lato;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    justify-content: center;

    &:hover {
        text-decoration: underline;
    }
}