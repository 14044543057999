@import '../../../assets/css/variables';

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    @media screen and (max-width: $small-screen) {
        gap: 24px;
    }
}

.topSection {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100px;
    margin-top: 20px;
    align-items: flex-start;
    @media screen and (max-width: $small-screen) {
        flex-direction: column;
    }
}

.rightSection {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    gap: 24px;
    @media screen and (max-width: $small-screen) {
        flex-direction: row-reverse;
        justify-content: space-between;
    }
}

.search {
    display: flex;
    flex: 1;
    width: 100%;
    @media screen and (max-width: $medium-screen) {
        flex-direction: column-reverse;
        gap: 8px;
    }
    @media screen and (min-width: $medium-screen) {
        margin-left: auto;
        gap: 16px;
    }
}

.filterAndView {
    display: flex;
    @media screen and (max-width: $large-screen) {
        gap: 4px;
    }
    @media screen and (min-width: $large-screen) {
        gap: 12px;
    }
}

.selectorWrapper {
    width: 120px;
}

.selector {
    flex: 1;
    width: 120px;
}

.input {
    display: flex;
    justify-content: flex-start;
    flex: 2;

    input {
        height: 44px;
        width: 100%;
        @media screen and (min-width: $medium-screen) {
            max-width: 320px;
        }
    }
}