.container {
    width: 352px;
    height: 224px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.title {
    font-size: 18px;
    font-weight: 700;
    color : #101828
}

.description {
    font-weight: 400;
    font-size: 16px;
    color: #667085;
    text-align: center;
}
