@import '../../../assets/css/variables';

.modalContainer {
    padding: 0;
    max-width: 510px;
    overflow-y: auto;
    overflow-x: hidden;

    @media screen and (max-width: $small-screen) {
        width: 94%;
    }
    @media screen and (min-width: $small-screen) {
        width: 100%;
    }
}

.container {
    display: flex;
    flex-direction: column;
    max-width: 510px;
    gap: 16px;
    border-radius: 12px;
    background-color: #FFFFFF;
    max-height: 90vh;
    z-index: 1000;
    box-sizing: border-box;
}

.modalHeader {
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: sticky;
    top: 0;
    z-index: 20;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.title {
    display: flex;
    justify-content: space-between;
    font-family: $font-family-lato;
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    color: #1F1F1F;
    padding: 24px 24px 8px 24px;
}

.body {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0 24px 0 24px;
}

.sendEmailText {
    font-family:  $font-family-lato;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: #242222;
}

.name {
    display: flex;
    gap: 16px;
    @media screen and (max-width: $small-screen) {
        flex-wrap: wrap;
    }
}

.bottom {
    display: flex;
    justify-content: space-between;
    padding: 0 24px 24px 24px;
}

.switch {
    display: flex;
    justify-content: center;
    align-items: center;
}

.notes {
    div {
        margin-bottom: 0;
    }
}

.uploadHeader {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
}

.uploadHeaderLabel {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    font-family:  $font-family-lato;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #464444;
}

.uploadHeaderBody {
    display: flex;
    flex-direction: row;
    gap: 12px;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.avatarContainer {
    padding-right: 8px;
}

.avatarImage {
    width: 64px;
    height: 64px;
    border-radius: 64px;
}

.customLabel {
    display: flex;
    flex-grow: 1;
    height: fit-content;
    justify-content: center;
    padding: 11px 17px;
    border: 1px solid #D9D9D9;
    border-radius: 6px;
    cursor: pointer;
    align-items: center;
    gap: 4px;
}

.input {
    div {
        height: 48px;
    }
}

.btnWrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}