@import '../../../assets/css/variables.scss';

.titleWrapper {
    display: flex;
    gap: 4px;
}

.text {
    width: 100%;
    font-family: $font-family-lato;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0;
    text-align: left;
    display: flex;
    flex: 1;
    color: #242222;
    text-wrap-mode: nowrap;

    @media screen and (max-width: $small-screen) {
        font-size: 32px;
    }
    @media screen and (min-width: $small-screen) {
        font-size: 36px;
    }
}

.titleCount {
    font-family: $font-family-lato;
    font-weight: 700;
    color: #242222;
    font-size: 14px;
}