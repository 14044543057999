@import '../../../assets/css/variables';

.container {
    display: flex;
    width: auto;
    gap: 16px;
    border-radius: 6px;
    background-color: #FFFFFF;
    border: 1px solid #242222;
    box-sizing: border-box;
    align-self: flex-end;
    cursor: pointer;
    height: 48px;

    @media screen and (max-width: $small-screen) {
        padding: 12px 12px 12px 8px;
    }
    @media screen and (min-width: $small-screen) {
        padding: 12px 24px 12px 16px;
    }
}

.plus {
    display: flex;
    font-size: 22px;
    align-items: center;
    margin-bottom: 3px;
}

.btn {
    background-color: #242222 !important;
    width: max-content !important;

    div {
        color: #FFFFFF
    }
}