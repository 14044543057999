@import "../../../../assets/css/variables.scss";

.container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 15px;
}

.inputsContainer {
    display: flex;
    width: -webkit-fill-available;
    gap: 24px;
}

@media screen and (max-width: $medium-screen){
    .inputsContainer {
        display: flex;
        flex-direction: column;
    }
}