.logoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    padding: 15px;
    flex-wrap: wrap;
}

.logoWrapper {
    display: flex;
    width: 40px;
    min-width: 40px;
    height: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 40px;
}

.dropdownWrapper {
    position: relative;
}

.customDropdown {
    display: block !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 200px;
    li {
        display: block !important;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.dropdownAdjust {
    right: 0;
}