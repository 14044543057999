@import "../../../assets/css/variables.scss";

.container {
  background-color: white;
  padding: 0 20px;
  border-radius: 16px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 20px;
}

.flexInput {
  display: flex;
  gap: 24px;
}

.customFilebtn {
  border: 1px solid #d9d9d9;
  padding: 11px 17px;
  border-radius: 6px;
  cursor: pointer;
  display: inline-block;
}

.customFilecover {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  z-index: 1;
  margin-top: -65px;
}

.customLabel {
  padding: 11px 17px;
  border: 1px solid #D9D9D9;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
}

@media screen and (max-width: $medium-screen) {
  .flexInput {
    flex-direction: column;
  }
}

.dirty {
  box-shadow: 
    0 0 8px 4px #DBEAFE,
    0 1px 3px 0 #0000001A,
    0 4px 4px 0 #00000040;
  border: 1px solid #93C5FD;
}

.label {
  color: #464444;
  margin-bottom: -15px;
}