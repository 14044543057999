@import '../../../assets/css/variables.scss';

.policy {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    font-family:  $font-family-lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
}

.link {
    display: inline-block;
    text-decoration: underline;
    cursor: pointer;
    margin-left: 4px;
}
