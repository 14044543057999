@import "../../../../assets/css/variables";

.input {
    div {
        justify-content: center;
        align-items: center;
    }
    div input {
        height: 48px;
        width: 100%;
        max-width: none;
    }
}

.desc {
    display: flex;
    align-items: center;
    padding: 12px;
    gap: 8px;
    border-radius: 6px;
    background-color: #DBEAFE;
    font-family: $font-family-lato;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #1E3A8A;
}

.warnIcon {
    width: 15px;
    height: 15px;
    min-width: 15px;
}

.connected {
    padding: 5px 10px 5px 10px;
    border-radius: 34px;
    background-color: #D1FAE5;
    font-family: $font-family-lato;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    color: #065F46;
}