@import '../../../assets/css/variables';

.container {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    background-color: #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0 1px 2px 0 #0000000F;
    border-radius: 16px;
    border: 1px solid #FFFFFF;
    min-height: 284px;
    max-height: 322px;

    @media screen and (min-width: $small-screen) and (max-width: $medium-screen) {
        max-width: 258px;
    }
    @media screen and (min-width: $medium-screen) {
        max-width: 288px;
    }
}

.border {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
    gap: 16px;
    background-color: #FFFFFF;
    padding: 22px 24px 24px 24px;
    border-radius: 16px;
}

.owner {
    height: 20px;
    font-size: 11px;
    position: absolute;
    top: 8px;
    left: 16px;
    font-family: $font-family-lato;
    color: #F5C189;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    width: 80%;
    overflow: hidden;
}

.imageWrapper {
    display: flex;
    position: relative;
}

.imageBox {
    width: 80px;
    height: 80px;
    border-radius: 104px;
}

.image {
    width: 80px;
    height: 80px;
    border-radius: 80px;
}

.status {
    display: flex;
    position: absolute;
    width: 14px;
    height: 14px;
    border-radius: 20px;
    margin-top: auto;
    bottom: 2px;
    right: 2px;
    z-index: 11;
    border: 2px solid #FFFFFF;
}

.active {
    filter: brightness(0) saturate(100%) invert(50%) sepia(99%) saturate(420%) hue-rotate(100deg) brightness(93%) contrast(86%);
}

.inactive {
    filter: brightness(0) saturate(100%) invert(18%) sepia(52%) saturate(7491%) hue-rotate(349deg) brightness(94%) contrast(97%);
}

.textBox {
    display: flex;
    flex-direction: column;
    gap: 4px;
    word-break: break-word;
}

.text {
    font-family: $font-family-lato;
    letter-spacing: 0;
    text-align: center;
}

.usernameText {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    color: #111827;
    text-overflow: ellipsis;
    overflow: hidden;
    max-height: 84px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.idText {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #4B5563;
}

.btnText {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0;
}

.footer {
    display: flex;
    width: 100%;
    height: 56px;
    margin-top: auto;
    border-top: 1px solid #E5E7EB;
    position: relative;
    box-sizing: border-box;
}

.footerSectionLeft, .footerSectionRight {
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: #242222;
}

.footerSectionLeft {
    border-bottom-left-radius: 8px;
    border-right: 1px solid #E5E7EB;
    gap: 4px;
}

.footerSectionRight {
    border-bottom-right-radius: 8px;
}

.headerBoxWrapper {
    display: flex;
    margin-left: auto;
    margin-bottom: -28px;
    width: 100%;
    justify-content: space-between;
}

.menuActive {
    background-color: #F0F0F0;
}

.moreIcon {
    width: 24px;
    height: 24px;
    filter: brightness(0) saturate(100%) invert(45%) sepia(3%) saturate(2201%) hue-rotate(180deg) brightness(103%) contrast(92%);
}

.editIcon {
    width: 20px;
    height: 20px;
}