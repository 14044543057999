@import '../../../assets/css/variables.scss';

.navContainer {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 10px;
    font-size: 14px;
    color: #464444;
    margin: 0 150px;
    padding: 20px 0;
    border-top: 1px solid #D9D9D9;

    @media screen and (max-width : $large-screen) {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        margin: 0;
    }
}

.privacyPolicy {
    display: flex;
    flex-wrap: nowrap;
    gap: 10px;
}

.firstDot {
    @media screen and (max-width : $large-screen) {
        display: none;
    }
}

.stickyFooter {
    @media screen and (max-width : $x-small-screen) {
        margin-bottom: 80px;
    }
}

.link:visited {
    color: inherit;
}

.version {
    display: flex;
    gap: 8px;
}
