@import '../../assets/css/variables.scss';

.container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    @media screen and (max-width: $large-screen) {
        padding: 20px 16px;
    }
    @media screen and (min-width: $large-screen) {
        padding: 40px 130px;
    }
}

.gridNameWrapper {
    display: flex;
    flex-direction:column;
    justify-content: center;
    max-width: 200px;
    color: #111827;
}

.gridEmail, .gridId, .row {
    display: flex;
    font-family: $font-family-lato;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    gap: 4px;
}

.gridEmail {
    color: #111827;
    font-weight: 700;
}

.gridId {
    color: #6B7280;
    font-weight: 500;
}

.notesTitle {
    font-family: $font-family-lato;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #464444;
}

.notesText {
    width: 100%;
    border: 1px solid #CDCDCD;
    border-radius: 6px;
    padding: 12px 13px 12px 13px;
    font-family: $font-family-lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #000000;
    box-sizing: border-box;
    word-break: break-word;
}

.loading {
    align-self: center;
    margin-top: 100px;
    margin-bottom: 120px;
}

.avatarImage {
    height: 48px;
    width: 48px;
    border-radius: 48px;
}