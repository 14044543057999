@import "../../../assets/css/variables.scss";

.container {
    display: flex;
    position: relative;
    flex-direction: column;
    width: -webkit-fill-available;
    gap: 2px;
}


.inputContainer {
    display: flex;
    width: 100%;
    height: 48px;
}


.input, .inputWithIcon {
    flex-grow: 1;
    box-sizing: border-box;
    padding: 12px 13px;
    border-radius: 6px;
    border: 1px solid rgb(230, 230, 230);
}

/* Chrome, Safari, Edge */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.input {
    max-width: 510px;
    padding: 12px 13px;
}

.inputWithIcon {
    display: flex;
    align-items: center;
    padding: 10px 38px 10px 13px;
    text-wrap: wrap;
    overflow-x: hidden;
    overflow-y: hidden;
    text-overflow: ellipsis;
}

.inputIcon {
    display: flex;
    align-items: center;
    cursor: pointer;
    text-wrap: wrap;
    overflow-x: hidden;
    overflow-y: hidden;
    text-overflow: ellipsis;
    position: absolute;
    right: 12px;
    transform: translateY(-50%);
    top: 50%;
}

.inputValueWrapper {
    padding: 2px 12px 5px 12px;
    border-radius: 48px;
    background-color: #F0F0F0;

    font-family: $font-family-lato;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    color: #1F2937;
}

.label {
    font-size: 15px;
    font-weight: 500;
    align-self: flex-start;
    color: #464444;
    line-height: 20px;
}

.inputWrapper {
    display: flex;
    position: relative;
}

.icon {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    right: 13px;
    cursor: pointer;
}

.hidden {
    display: none;
}

.error, .success {
    position: absolute;
    font-size: 13px;
    color: #ff0000;
    bottom: -16px;
    text-wrap: nowrap;
}

.error {
    color: #ff0000;
}

.success {
    color: #059669;
}