@import '../../../assets/css/variables.scss';

.container {
    background-color: white;
    padding: 0 20px;
    border-radius: 16px;

}

.inputsContainer {
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    border-top: 1px solid #d9d9d9;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-bottom: 20px;
}

.inputContainer {
    display: flex;
    align-items: center;
    gap: 12px
}

.linkSelector {
    @media screen and (max-width: $small-screen) {
        min-width: 120px !important;
    }
    @media screen and (min-width: $small-screen) and (max-width: $medium-screen)  {
        min-width: 140px !important;
    }
    @media screen and (min-width: $medium-screen) {
        min-width: 188px !important;
    }
}

.selectInput {
    max-width: 30%;
    padding: 12px 10px;
    border-radius: 6px;
    border: 1px solid rgb(230, 230, 230);
    width: 120px;
}

select {
    -webkit-appearance: none;
    appearance: none;
    background-image: url("../../../assets/icons/arrow/arrow-down.svg");
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) center;
    cursor: pointer;
}

.minus {
    width: 16px;
    height: 16px;
    min-width: 16px;
    cursor: pointer;
}

.addLink {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.addText {
    color: #1D4ED8;
    font-size: 16px;
    font-weight: 400;
}

.bold {
    font-weight: bold;
}

.dirty {
    box-shadow: 
      0px 0px 8px 4px #DBEAFE,
      0px 1px 3px 0px #0000001A,
      0px 4px 4px 0px #00000040;
    border: 1px solid #93C5FD;
  }