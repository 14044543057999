@import '../../../assets/css/variables';

.header {
    display: flex;
    flex-direction: column;
}

.icon {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}

.typeIcon {
    align-self: center;
}

.title, .text {
    font-family: $font-family-lato;
    text-align: center;
}

.title {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: #242222;
}

.text {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #464444;
}

.btn {
    display: flex;
    width: fit-content;
    justify-content: center;
    align-items: center;
    padding: 12px 24px 12px 24px;
    gap: 8px;
    border-radius: 6px;
    background: #FFFFFF;
    border: 1px solid #242222;
    cursor: pointer;
    align-self: center;
}


