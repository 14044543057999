@import '../../assets/css/variables.scss';

.container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    @media screen and (max-width: $small-screen) {
        padding: 20px 16px;
    }
    @media screen and (min-width: $small-screen) and (max-width: $large-screen) {
        padding: 20px 65px;
    }
    @media screen and (min-width: $large-screen) {
        padding: 40px 130px;
    }
}

.statsBox {
    display: flex;
    gap: 20px;

    @media screen and (max-width: $small-screen) {
        flex-direction: column;
    }
}