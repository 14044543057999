@import '../../assets/css/variables';

.wrapper {
    display: flex;
    flex-direction: column;
}

.top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    padding-top: 56px;

    @media screen and (max-width: $small-screen) {
        gap: 24px;
    }
}

.title {
    font-size: 36px;
    font-weight: 700;
}

.searchInput {


    input {
        width: 646px;
        height: 60px;

        @media screen and (max-width: $small-screen) {
            width: 343px;
        }
    }
}

.categories {
    padding: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 24px;

    @media screen and (max-width: $small-screen) {
        padding: 24px;
    }
}

.container {
    display: flex;
    justify-content: center;
    padding: 70px 40px;
    flex-wrap: wrap;
    gap: 48px;

    @media screen and (max-width: $small-screen) {
        padding: 15px;
        gap: 10px;
    }
}

.categoryWrapper {
    background-color: #FFFFFF;
    border: 1px solid #E5E7EB;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 48px 64px;
    width: 792px;

    @media screen and (max-width: $small-screen) {
        padding: 24px;
    }
}

.categoriesTable {
    background-color: #FFFFFF;
    border: 1px solid #E5E7EB;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 32px;
    width: 360px;
    height: fit-content;

    @media screen and (max-width: $small-screen) {
        padding: 24px;
    }
}

.title {
    font-size: 22px;
    font-weight: 700;

}

.tableTitle {
    font-size: 16px;
    font-weight: 600;
}

.categoryTitle {
    color: #2563EB;
    font-size: 18px;
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
        color: #242222;
    }
}

.articles {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.articleWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.article {
    color: #2563EB;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
}

.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;

    @media screen and (max-width: $small-screen) {
        flex-direction: column-reverse;
        align-items: flex-start;
        gap: 16px;
    }
}

.footerLeft {
    display: flex;
    align-items: center;
    gap: 12px;
}

.contact {
    color: #2563EB;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

.text {
    color: #464444;
    font-size: 16px;
}

.contentWrapper {
    background-color: #FFFFFF;
    border: 1px solid #E5E7EB;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 48px 64px;
    width: 792px;

    @media screen and (max-width: $small-screen) {
        padding: 24px;
    }
}

.loading {
    align-self: center;
    margin-top: 100px;
    margin-bottom: 120px;
}