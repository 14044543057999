@import '../../../assets/css/variables';

.container {
    position: relative;
}

.export {
    display: flex;
    gap: 4px;
    color: #242222;
    cursor: pointer;
    justify-content: center;
    align-items: center;
}

.modal {
    display: flex;
    justify-content: flex-start;
    position: absolute;
    width: fit-content;
    min-width: 200px;
    padding: 8px;
    top: 32px;
    right: 0;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0 8px 16px 0 #00000014;
}


.text, .textActive {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    font-family: $font-family-lato;
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
    border-radius: 4px;
    padding: 8px 12px 8px 12px;
    cursor: pointer;
}

.textActive {
    background-color: #F2F2F2;
}