@import '../../../assets/css/variables.scss';

.settings {
    padding: 23px 20px 23px 20px;
    flex-direction: row;
    align-items: center;
}

.settingsBox {
    gap: 16px;
}

.btn {
    height: fit-content;
    padding: 10px 15px 10px 15px;
    border-radius: 8px;
    background: #FFFFFF;
    box-sizing: border-box;
    border: 1px solid #D0D5DD;
    box-shadow: 0 1px 2px 0 #1018280D;
}

.btnText {
    font-family: $font-family-lato;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #242222;
}

.header {
    display: flex;
    text-transform: uppercase;
    font-family: $font-family-lato;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.05em;
    text-align: left;
    color: #6B7280;
    justify-content: space-between;
    margin-top: 16px;
    margin-right: 24px;
}

