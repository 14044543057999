@import '../../assets/css/variables';

.header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}

.headerTxt {
    font-size: 20px;
    font-weight: 700;
    color: #1F1F1F;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.input {
    margin-bottom: 16px;
}
