@import '../../../assets/css/variables';

.container {
    display: flex;
    flex-direction: column;
    max-width: 510px;
    gap: 16px;
    border-radius: 12px;
    background-color: #FFFFFF;
    max-height: 90vh;
    z-index: 1000;
    box-sizing: border-box;
}

.title {
    display: flex;
    gap: 8px;
    justify-content: space-between;
    font-family: $font-family-lato;
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    color: #1F1F1F;
    padding: 24px 24px 8px 24px;
}

.titleTextWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.subTitle {
    font-family: $font-family-lato;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #464444;
}

.body {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0 24px 0 24px;
}

.footer, .footerFeature {
    display: flex;
    justify-content: space-between;
    padding: 0 24px 24px 24px;

    @media screen and (max-width: $small-screen) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
    }
}

.footerFeature {
    justify-content: flex-end !important;
}

.contacts {
    display: flex;
    gap: 16px;
    align-items: center;
}

.modalContainer {
    width: 570px;
    padding: 0 !important;

    div {
        max-width: 570px !important;
    }

    @media screen and (max-width: $small-screen) {
        width: 90%;
    }
}

.grayInput {
    input {
        height: 48px;
        box-sizing: border-box;
        background: #F0F0F0;
    }
}

.input {
    input {
        height: 48px;
        box-sizing: border-box;
    }
}

.fileUploadContainer {
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.fileUploadLabel {
    cursor: pointer;
    display: inline-block;
    width: 100%;
}

.fileUploadBox {
    border: 1px dashed #929497;
    border-radius: 8px;
    transition: border-color 0.3s ease;
    height: 162px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 8px;

    &:hover {
        border-color: #007bff;
    }
}

.fileUploadtitle {
    color: #111827;
    font-size: 20px;
    font-weight: 700;
}

.fileUploadsubtitle {
    color: #808080;
    font-size: 15px;
}

.fileInput {
    display: none;
}

.selectedFiles {
    display: flex !important;
    border: 1px solid #D9D9D9;
    padding: 13px;
    justify-content: space-between;
    border-radius: 8px;
    align-items: center;
    gap: 8px;
}

.selectedFiles ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.selectedFiles li {
    font-size: 14px;
    color: #333;
    margin-top: 8px;
}

.selectedFiles span {
    display: flex !important;
    align-items: center;
    flex: 1;
}


.fileSide, .fileSideName {
    display: flex !important;
    gap: 5px;
}

.fileSideName {
    flex: 1;
}

.close {
    display: flex;
    cursor: pointer;
}

.label {
    color: #464444;
    font-size: 15px;
}
