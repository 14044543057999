@import '../../assets/css/variables.scss';

.boxContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 24px;
    border-radius: 16px;
    background-color: #FFFFFF;
    box-sizing: border-box;
    margin-bottom: 40px;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 1000px;

    @media screen and (max-width: $small-screen) {
        padding: 16px 16px 30px 16px;
    }

    @media screen and (min-width: $small-screen) {
        padding: 30px 24px 30px 24px;
    }
}

.titleContainer {
    display: flex;
    flex: 1;
    justify-content: space-between;
    flex-wrap: wrap;

    @media screen and (max-width: $large-screen) {
        gap: 32px;
        flex-direction: column;
    }
}

.titleWrapper {
    display: flex;
    gap: 12px;
    align-items: center;
}

.backIcon {
    rotate: 180deg;
    cursor: pointer;
}

.title {
    font-family: $font-family-lato;
    font-size: 22px;
    font-weight: 700;
    line-height: 28px;
    text-align: left;
    color: #111827;
}

.cardContainer {
    display: flex;
    width: auto;
    height: auto;
    padding: 28px 20px 28px 20px;
    gap: 20px;
    border-radius: 8px;
    justify-content: space-between;
    border: 1px solid #D9D9D9;
    text-align: center;

    @media screen and (max-width: $small-screen) {
        flex-direction: column;
        align-items: flex-start;
    }

    @media screen and (min-width: $small-screen) {
        align-items: center;
    }
}

.containerLeftSection {
    display: flex;
    gap: 20px;
    align-items: center;
}

.cardTextWrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 4px;
}

.cardLabel {
    font-family: $font-family-lato;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    color: #242222;
}

.cardText {
    font-family: $font-family-lato;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #808080;
}

.cardElement {
    display: flex;
    text-align: center;
    align-items: center;
    cursor: pointer;
}

.pointer {
    cursor: pointer;
}