@import '../../assets/css/variables.scss';

.container {
    background-color: #FFFFFF;
    border-radius: 8px;
    box-shadow: 0 1px 2px 0 #0000000F;
}

.header {
    display: flex;
    padding: 20px 24px 20px 24px;
}

.title {
    font-family: $font-family-lato;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #111827;
}

.headerText {
    font-family: $font-family-lato;
    font-weight: 600;
    color: #6B7280;
    text-align: left;

    @media screen and (max-width: $medium-screen) {
        font-size: 12px;
        line-height: 16px;
    }
    @media screen and (min-width: $medium-screen) {
        font-size: 14px;
        line-height: 20px;
    }
}

.text {
    display: flex;
    align-items: center;
    font-family: $font-family-lato;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
}

.topCard {
    display: flex;
    border-top: 1px solid #E5E7EB;
    box-sizing: border-box;

    @media screen and (max-width: $medium-screen) {
        padding: 16px 12px 16px 16px;
        flex-direction: column;
    }
    @media screen and (min-width: $medium-screen) {
        padding: 20px 24px 20px 24px;
        flex-direction: row;
        gap: 4px;
    }
}

.cardUser {
    display: flex;
    gap: 12px;
}

.avatar {
    height: 40px;
    width: 40px;
    border-radius: 40px;
}

.usernameWrapper {
    display: flex;
    flex-direction:column;
    justify-content: center;
    max-width: 200px;
    color: #111827;
}

.gridEmail, .gridName, .row {
    display: flex;
    font-family: $font-family-lato;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    gap: 4px;
}

.gridEmail {
    color: #111827;
    font-weight: 600;
}


.gridName {
    color: #6B7280;
    font-weight: 400;
}

.firstColumn {
    @media screen and (max-width: $medium-screen) {
        width: 100%;
    }
    @media screen and (min-width: $medium-screen) {
        width: 40%;
    }
}

.otherColumn {
    display: flex;
    @media screen and (max-width: $medium-screen) {
        width: 100%;
    }
    @media screen and (min-width: $medium-screen) {
        width: 60%;
    }
}

.column {
    width: 33.33%
}

.headerColumnRightSection {
    @media screen and (max-width: $medium-screen) {
        display: none;
    }
}

.columnRightSection {
    @media screen and (max-width: $medium-screen) {
        margin-top: 16px;
        gap: 4px;
    }
    @media screen and (min-width: $medium-screen) {
        display: none;
    }
}