.btnContainer {
    display: flex;
    justify-content: flex-end;
  }
  
  .cancelBtn {
    background-color: #fff;
    padding: 12px 24px;
    border-radius: 6px;
    border: none;
    font-size: 16px;
    font-weight: 500;
    height: 48px;
  }
  
  .saveBtn {
    background-color: #242222;
    padding: 12px 24px;
    color: #fff;
    border-radius: 6px;
    border: none;
    font-size: 16px;
    font-weight: 500;
    box-sizing: border-box;
    max-height: 48px;
    justify-content: center;
    display: flex;
    align-items: center;
  }
