@import '../../../assets/css/variables';

.modalContainer {
    padding: 0;

    @media screen and (max-width: $small-screen) {
        min-width: 90%;
    }
}

.container {
    display: flex;
    padding: 24px;
    flex-direction: column;
    gap: 8px;
    border-radius: 12px;
    background-color: #FFFFFF;
    box-sizing: border-box;
}

.header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
}

.titleText {
    font-family: $font-family-lato;
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    color: #1F1F1F;
}

.companies {
    height: 200px;
    overflow-y: auto;
}

.company {
    width: 100%;
    height: 40px;
    padding: 8px 16px 8px 14px;
    background-color: #FFFFFF;
    color: #242222;
    box-sizing: border-box;
    cursor: pointer;
    text-align: start;
}

.companySelected {
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background-color: #3F435014;
}


.btnWrapper {
    display: flex;
    margin-left: auto;
}

.btnDiscard {
    background-color: #FFFFFF !important;
    height: 48px;
    border: none;

    div {
        color: #242222
    }
}

.footer {
    display: flex;
    gap: 8px;
    justify-content: center;
    text-align: left;

    @media screen and (max-width: $small-screen) {
        flex-direction: column;
        align-items: flex-start;
    }
    @media screen and (min-width: $small-screen) {
        flex-direction: row;
        align-items: center;
    }
}

.footerText {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $font-family-lato;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: #242222;
    gap: 15px;
}

.input {
    input {
        width: 100%;
    }
}