@import '../../../assets/css/variables';

.container {
    position: absolute;
    border-radius: 8px;
    background: #FFFFFF;
    z-index: 21;
    top: 60px;
    right: 25px;
    padding: 2px 16px 2px 16px;
    box-sizing: border-box;
    box-shadow: 0 0 0 1px #0000000D;
    @media screen and (max-width: $small-screen) {
        max-width: 300px;
        top: 292px;
    }
    @media screen and (min-width: $small-screen) {
        max-width: 362px;
        left: -100px;
        top: 292px;
    }
}

.item {
    display: flex;
    width: 100%;
    gap: 8px;
    padding: 11px 0 11px 0;
    box-sizing: border-box;
    justify-content: flex-start;
    align-items: center;
}

.pointer {
    cursor: pointer;
}

.iconWrapper {
    width: 24px;
    height: 24px;
}

.icon {
    filter: invert(78%) sepia(0%) saturate(27%) hue-rotate(287deg) brightness(89%) contrast(90%);
}

.inactiveWrapper {
    flex-direction: column;
    gap: 0 !important;
    padding-top: 4px;
}

.inactive {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
}

.switchWrapper {
    margin-left: auto;
}

.subText {
    font-family: $font-family-lato;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    margin: 0 38px 0 32px;
    letter-spacing: 0;
    color: #808080;
}

.text {
    font-family: $font-family-lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
}

.deleteText {
    color: #B91C1C
}