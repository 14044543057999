@import '../../../assets/css/variables.scss';

.container {
    display: flex;
    width: 100%;
    padding: 0 16px 0 16px;
    border-radius: 16px;
    background-color: #FFFFFF;
    box-shadow: 0 1px 2px 0 #0000000F;
    align-items: center;
    min-width: 0;
    @media screen and (max-width: $small-screen) {
        flex-direction: column;
        height: auto;
        padding: 24px 16px 24px 16px;
        gap: 24px;
    }
    @media screen and (min-width: $small-screen) {
        flex-direction: row;
        height: 96px;
        padding: 0 16px 0 16px;
    }
}

.menuContainer {
    width: max-content;
    @media screen and (max-width: $small-screen) {
        max-width: 300px !important;
        top: 40px !important;
        right: 12px !important;
    }
    @media screen and (min-width: $small-screen) {
        max-width: 362px;
        top: 40px !important;
        right: 0 !important;
        left: -340px !important;
    }
}

.leftSection {
    display: flex;
    align-items: center;
    gap: 16px;
    @media screen and (max-width: $small-screen) {
        width: 100%;
        align-self: flex-start;
        align-items: flex-start;
    }
    @media screen and (min-width: $small-screen) {
        width: 60%;
    }
}

.userInfoSection {
    display: flex;
    width: 100%;
    min-width: 0;
    @media screen and (max-width: $small-screen) {
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
    }
    @media screen and (min-width: $small-screen) {
        flex-direction: row;
        align-items: center;
        gap: 8px;
    }
}

.rightSection {
    display: flex;
    align-items: center;
    gap: 8px;
    position: relative;
    @media screen and (max-width: $small-screen) {
        width: 100%;
    }
    @media screen and (min-width: $small-screen) {
        width: 40%;
        margin-left: auto;
        justify-content: flex-end;
    }
}

.textId {
    display: flex;
    justify-content: flex-start;
    font-family: $font-family-lato;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: #242222;
    @media screen and (max-width: $small-screen) {
        width: 100%;
    }
    @media screen and (min-width: $small-screen) {
        max-width: 60px;
        min-width: 60px;
    }
}

.imageWrapper {
    display: flex;
    position: relative;
}

.imageBox {
    width: 48px;
    height: 48px;
    border-radius: 48px;
}

.image {
    width: 48px;
    height: 48px;
    border-radius: 48px;
}

.fullnameBox {
    display: flex;
    position: relative;
    flex-direction: column;
    min-width: 0;
    width: 100%;
    @media screen and (max-width: $small-screen) {
        gap: 8px;
    }
    @media screen and (min-width: $small-screen) {
        gap: 4px;
    }
}

.owner {
    height: 20px;
    font-size: 11px;
    position: absolute;
    font-family: $font-family-lato;
    color: #F5C189;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    max-width: 240px;
    overflow: hidden;
    @media screen and (max-width: $small-screen) {
        top: -44px;
    }
    @media screen and (min-width: $small-screen) {
        top: -28px;
    }
}

.text {
    font-family: $font-family-lato;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    text-overflow: ellipsis;
    text-wrap: nowrap;
    overflow-x: hidden;
}

.profileIndicatorWrapper {
    margin-right: 16px;
}

.name {
    color: #111827;
    font-weight: 600;
    margin-right: 16px;
    @media screen and (min-width: $small-screen) {
        margin-left: 8px;
    }
}

.email {
    color: #6B7280;
    font-weight: 400;
    margin-right: 16px;
}

.active {
    color: #00743E;
}

.inactive {
    color: #B91C1C;
}

.notActivated {
    color: #808080;
}

.moreIcon {
    width: 34px;
    height: 34px;
    filter: brightness(0) saturate(100%) invert(45%) sepia(3%) saturate(2201%) hue-rotate(180deg) brightness(103%) contrast(92%);
}

.threeDots {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.menuActive {
    background-color: #F0F0F0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #242222;
    border-radius: 28px;
}

.editBtn {
    height: 38px;
    padding: 12px;
    border: 1px solid #D1D5DB;
    @media screen and (max-width: $small-screen) {
        width: 100%;
    }
    svg {
        width: 20px;
        height: 20px;
    }
    div {
        font-family: $font-family-lato;
        font-size: 14px;
        line-height: 20px;
        color: #141414;
        font-weight: 600;
    }
}

.headerRow {
    display: flex;
    width: 100%;
    gap: 16px;
    justify-content: flex-start;
    padding: 0 8px;
    align-items: center;
    color: #111827;
    font-size: 14px;
    font-weight: 400;
    font-family: $font-family-lato;
}