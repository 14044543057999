@import "../../../assets/css/variables.scss";

.container {
  background-color: white;
  padding: 0 20px;
  border-radius: 16px;
}

.form {
  display: flex;
  flex-direction: column;
  border-top: 1px solid #d9d9d9;
  padding: 20px 0;
  gap: 24px;
}

.labelContainer {
  display: flex;
  justify-content: space-between;
}

.label {
  font-size: 15px;
  font-weight: 700;
  color: #464444;
}

.textarea {
  border: 1px solid #cdcdcd;
  font-family: $font-family-lato;
  font-size: 16px;
}

.dirty {
  box-shadow: 
    0px 0px 8px 4px #DBEAFE,
    0px 1px 3px 0px #0000001A,
    0px 4px 4px 0px #00000040;
  border: 1px solid #93C5FD;
}