.navContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    height: 70px;
    gap: 10px;
}

.mobileNavContainer {
    display: flex;
    // justify-content: center;
    // align-items: center;
    background-color: white;
    padding: 12px;
    // height: 70px;
    gap: 18px;
}


.mobileNavLinks {
    // margin-top: 84px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
    width: 100%;
   
}

.mobileLink {
    text-decoration: none;
    color: #111827;
    font-weight: 600;
    font-size: 16px;
    padding: 8px 12px;

    &:hover {
        background-color: #F0F0F0;
        border-radius: 6px;
    }
}


.navLinks {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
}

.link {
    text-decoration: none;
    color: #1A1523;
}

.activeLink {
    text-decoration: none;
    color: #1A1523;
    border-bottom: 4px solid red;
    padding: 24px 0
}

.selectorContainer {
    padding: 16px;
    background-color: #FFFFFF;

    .selector {
        width: 100%;
    }
}

