@import '../../../assets/css/variables';

.syncWrapper {
    display: flex;
    align-items: center;
    gap: 8px;

    @media screen and (max-width: $medium-screen) {
        justify-content: flex-end;
    }
    @media screen and (min-width: $medium-screen) {
        justify-content: center;
    }
}

.icon {
    min-width: 24px;
}

.spinner {
    min-width: 24px;

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    animation: spin 4s linear infinite;
}

.syncText {
    font-family: $font-family-lato;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #667085;
    @media screen and (min-width: $x-small-screen) {
        margin-right: 8px;
    }
}

.btn {
    background-color: #FFFFFF !important;
    height: 44px;
    width: max-content !important;
    border: 1px solid #D1D5DB;
    box-shadow: 0 1px 2px 0 #0000000D;
    padding: 11px 17px 11px 17px;

    div {
        color: #121111;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
    }
}
