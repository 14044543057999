@import '../../assets/css/variables.scss';

.container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    border-radius: 16px;
    gap: 2%;
    @media screen and (max-width: $large-screen) {
        padding: 20px 65px;
    }
    @media screen and (min-width: $large-screen) and (max-width: $xl-large-screen)  {
        padding: 40px 40px;
    }
    @media screen and (min-width: $xl-large-screen) {
        padding: 40px 130px;
    }
}

.leftContainer {
    width: 33%;
}

.editingHeader {
    font-family: $font-family-lato;
    font-size: 36px;
    font-weight: 700;
    line-height: 38px;
    text-align: left;
    text-underline-position: from-font;
    color: #242222;
    @media screen and (max-width: $small-screen) {
        font-size: 28px;
        line-height: 28px;
        padding: 16px 16px 0 16px;
    }
    @media screen and (min-width: $small-screen) and (max-width: $large-screen) {
        font-size: 28px;
        line-height: 28px;
        padding: 16px 65px 0 65px;
    }
    @media screen and (min-width: $large-screen) and (max-width: $xl-large-screen)  {
        padding: 36px 40px 0 40px;
    }
    @media screen and (min-width: $xl-large-screen) {
        padding: 52px 130px 16px 130px;
    }
}

.rightContainer {
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    gap: 10px;
    width: 65%;
}

@media screen and (max-width: $small-screen) {
    .container {
        padding: 40px 16px;
    }
}

@media screen and (max-width: $large-screen) {
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .leftContainer, .rightContainer {
        @media screen and (max-width: $small-screen) {
            width: 100%;
        }
        @media screen and (min-width: $small-screen) {
            width: 90%;
        }
    }
}

.sticky {
    margin-top: 143px;
}
