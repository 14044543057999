@import '../../../assets/css/variables';

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    @media screen and (max-width: $large-screen) {
        padding: 12px 0 32px 0;
        flex-direction: column;
        gap: 22px;
    }
    @media screen and (min-width: $large-screen) {
        padding: 24px 0 64px 0;
    }
}

.text {
    width: 100%;
    font-family: $font-family-lato;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0;
    text-align: left;

    @media screen and (max-width: $small-screen) {
        font-size: 32px;
    }
    @media screen and (min-width: $small-screen) {
        font-size: 36px;
    }
}

.filter {
    display: flex;
    gap: 16px;
    width: -webkit-fill-available;
    @media screen and (min-width: $large-screen) {
        margin-left: auto;
    }
}

.selector {
    flex: 1
}