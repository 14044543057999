.app {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.cropContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 80px;
    height: 310px;
}

.controls {
    position: absolute;
    bottom: 50px;
    height: 40px;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.slider {
    padding: 2px 0;
}

.sliderContainer {
    display: flex;
    flex: 1;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 0 8px 0 8px;
    box-sizing: border-box;
}

.sliderLabel {
    width: 130px;
}

.sliderWrapper {
    display: flex;
    width: 220px;
    justify-content: center;
    align-items: center;
}

.btn {
    height: 38px;
    min-width: 100px;
    justify-content: center;
    max-width: 140px;
}