@import '../../../assets/css/variables.scss';

.inviteUserContainer {
    display: flex;
    gap: 8px;
    cursor: pointer;

    @media screen and (min-width: $large-screen) {
        padding: 0 24px 0 16px;
    }

    @media screen and (max-width: $large-screen) {
        padding: 0;
    }
}

.text {
    font-family: $font-family-lato;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #242222;
}

.tableHeader {
    display: flex;
    text-transform: uppercase;
    font-family: $font-family-lato;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.05em;
    text-align: left;
    color: #6B7280;
}

.member {
    width: 52%;
}

.role {
    width: 16%;
    margin-left: 2px;
}

.title {
    width: 32%;
    margin-left: -10px;
}

.settings {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.searchInput {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    margin-bottom: 8px;
    input {
        width: 100%;
    }
}

.loading {
    align-self: center;
    margin-top: 40px;
    margin-bottom: 40px;
}