@import "../../../assets/css/variables.scss";

.container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: white;
    padding: 24px;
    border-radius: 16px;
}

.title {
    font-family: $font-family-lato;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    color: #111827;
}

.box {
    display: flex;
    width: 100%;
    max-width: 510px;
    @media screen and (max-width: $small-screen) {
        gap: 8px;
    }

    @media screen and (min-width: $small-screen) {
        gap: 24px;
    }
}

.btn {
    height: 48px;
    background-color: #242222;

    @media screen and (max-width: $small-screen) {
        padding: 12px;
    }

    div {
        color: #FFFFFF;
    }
}

.pending {
    color: #ED1C24;
    font-size: 12px;
    font-weight: 700;
    display: flex;
    width: 100%;
    word-break: break-word;
}

.selector {
    flex: 1
}

.selector input {
    padding: 2px 12px 5px 12px !important;
    border-radius: 48px;
    background-color: #F0F0F0;

    font-family: $font-family-lato;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    color: #1F2937;
    max-width: none;
    box-sizing: border-box;
    height: initial;
}