@import '../../assets/css/variables';

.headerContainer {
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: white;
    gap: 24px;
    border-radius: 8px;
    @media screen and (max-width: $small-screen) {
        padding: 16px 16px 0 16px;
    }
    @media screen and (min-width: $small-screen) {
        padding: 24px 24px 0 24px;
    }
}

.header {
    display: flex;
    justify-content: space-between;
    gap: 4px;
}

.modalContainer {
    padding: 0;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: 12px;
    background-color: #FFFFFF;
    max-height: 90vh;
    z-index: 1000;
    box-sizing: border-box;
}

.textHeaderWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.headerTxt {
    font-size: 20px;
    font-weight: 700;
    color: #1F1F1F;
    text-align: left;
}

.modalDesc {
    font-family: $font-family-lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #464444;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 8px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 16px 0 16px;
    @media screen and (max-width: $small-screen) {
        padding: 0 16px 0 16px;
    }
    @media screen and (min-width: $small-screen) {
        padding: 0 24px 0 24px;
    }
}

.icon {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.footer {
    display: flex;
    gap: 8px;
    justify-content: flex-end;
    @media screen and (max-width: $small-screen) {
        padding: 0 16px 16px 16px;
    }
    @media screen and (min-width: $small-screen) {
        padding: 0 24px 24px 24px;
    }
}

.btnDiscard {
    background-color: #FFFFFF !important;
    height: 48px;
    border: none;

    div {
        color: #242222
    }
}

.form {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.permissionCategory {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.permissionTextHeader {
    text-align: left !important;
    font-weight: 400 !important;
    color: #464444 !important;
}

.permissions {
    display: flex;
    gap: 8px;
    justify-content: space-between;
}

.leftColumn, .rightColumn {
    display: flex;
    flex: 1;
    align-items: flex-start;
    flex-direction: column;
    gap: 6px;
}