@import '../../../assets/css/variables';

.container {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    z-index: 5001;
    background-color: #00000040;
}

.modal {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 512px;
    border-radius: 12px;
    gap: 16px;
    box-shadow: 0 10px 10px -5px #0000000A;
    background-color: #FFFFFF;
    margin: 16px;

    @media screen and (max-width: $small-screen) {
        padding: 24px;
        width: 300px;
    }

    @media screen and (min-width: $small-screen) {
        padding: 24px;
    }
}