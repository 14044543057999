@import '../../../assets/css/variables';

.container {
    display: flex;
    gap: 16px;
    align-items: center;
    position: relative;
}

.formSelector {
    @media screen and (max-width: $medium-screen) {
        width: 100%;
    }
    @media screen and (min-width: $medium-screen) {
        width: auto;
    }
}

.selectAll {
    text-decoration: underline;
    cursor: pointer;

    @media screen and (max-width: $medium-screen) {
        display: none;
    }
}

.warningDialog {
    position: absolute;
    top: 56px;
    right: 0;
    border-radius: 8px;
    padding: 16px;
    background-color: #FFF1E0;
    box-shadow: 0 8px 16px 0 #00000014;
    font-family: $font-family-lato;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #ED1B24;
    box-sizing: border-box;
    z-index: 5;

    @media screen and (max-width: $medium-screen) {
        width: 100%;
    }
    @media screen and (min-width: $medium-screen) {
        width: 240px;
    }
}
