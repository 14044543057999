@import '../../../assets/css/variables';

.container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.imageContainer {
    display: flex;
    align-items: center;
    gap: 20px;
}

.profileUploadBtnContainer {
    display: flex;
    flex: 1;
    gap: 12px;
    align-items: center;
}

.photoImage {
    display: flex;
    flex: 1;
    padding: 10px 8px;
    border: 1px solid #D9D9D9;
    border-radius: 6px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    gap: 4px;
    color: #242222;
}

.orText, .label {
    font-family: $font-family-lato;
    line-height: 20px;
    color: #464444;
}

.orText {
    font-weight: 400;
    font-size: 16px;
}

.label {
    font-weight: 500;
    font-size: 15px;
    text-align: start;
}

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
}