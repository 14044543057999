@import "../../../assets/css/variables.scss";

.container {
  background-color: white;
  padding: 0 20px;
  border-radius: 16px;
}

.inputsContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-top: 20px;
  border-top: 1px solid #d9d9d9;
}

.inputContainer {
  display: flex;
  align-items: center;
  gap: 12px;
}

.linkInput {
  padding: 12px 13px;
  border-radius: 6px;
  border: 1px solid rgb(230, 230, 230);
  width: -webkit-fill-available;
}

.selectInput {
  max-width: 30%;
  padding: 12px 10px;
  border-radius: 6px;
  border: 1px solid rgb(230, 230, 230);
  width: 120px;
}

select {
  -webkit-appearance: none;
  appearance: none;
  background-image: url("../../../assets/icons/arrow/arrow-down.svg");
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
  cursor: pointer;
}

.minus {
  width: 16px;
  height: 16px;
  min-width: 16px;
  cursor: pointer;
}

.addLink {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.addText {
  color: #1d4ed8;
  font-size: 16px;
  font-weight: 400;
}

.flexInput {
  display: flex;
  gap: 24px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 20px;
}

@media screen and (max-width: $medium-screen) {
  .flexInput {
    flex-direction: column;
  }
}

.dirty {
  box-shadow: 
    0px 0px 8px 4px #DBEAFE,
    0px 1px 3px 0px #0000001A,
    0px 4px 4px 0px #00000040;
  border: 1px solid #93C5FD;
}