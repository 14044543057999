$font-path: '../css/fonts';
$font-family-lato: 'Lato';
$font-family-saira: 'Saira';
$font-family-saira-semibold: 'Saira-semibold';

$x-small-screen: 500px;
$small-screen: 576px;
$medium-screen: 768px;
$large-screen: 992px;
$xl-large-screen: 1200px;
$xxl-large-screen: 1400px;

@font-face {
    font-family: 'Lato';
    src: url('#{$font-path}/Lato-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Saira';
    src: url('#{$font-path}/Saira-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Saira-semibold';
    src: url('#{$font-path}/Saira-SemiBold.ttf') format('truetype');
}

.textEllipsis {
    display: block !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
}

.ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
}

.textNewLine {
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
    width: 100%;
}

.btnLight {
    background-color: #FFFFFF !important;
    width: max-content !important;

    div {
        background-color: #FFFFFF !important;
        color: #242222
    }
}

.btnDark {
    background-color: #242222 !important;
    height: 48px;
    width: max-content !important;

    div {
        color: #FFFFFF
    }
}