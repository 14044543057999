@import '../../../assets/css/variables.scss';

.menuContainer {
    display: flex;
    margin-left: auto;
    width: 32px;

    @media screen and (max-width: $large-screen) {
        align-self: flex-start;
    }
}

.menuActive {
    width: 32px;
    height: 32px;
    background-color: #F0F0F0;
    border-radius: 50%;
}

.dotsIcon {
    cursor: pointer;
    margin-left: auto;
    width: 32px;
    height: 32px;
}