.coverInput {
  display: none;
}

.label {
  cursor: pointer;
  color: #464444;
}

.uploadInput {
  display: none;
}

.coverContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 25px;
  border: 1px dashed #d9d9d9;
  margin-top: 4px;
}

.coverExist {
  position: relative;
  width: 100%;
}

.coverBtn {
  background-color: #fff;
  padding: 11px 15px;
  border-radius: 6px;
  font-size: 16px;
  border: 1px solid #D9D9D9;
}

.btnContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  gap: 8px;
}

.profileBtnContainer {
  display: flex;
  gap: 8px;
}

.deleteBtn {
  cursor: pointer;
  background-color: #fff;
  color: #DC2626;
  padding: 11px 15px;
  border-radius: 6px;
  font-size: 16px;
  border: 1px solid #D9D9D9;
}

.coverImage {
  width: 100%;
}

.customLabel {
  padding: 11px 17px;
  border: 1px solid #D9D9D9;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
}

.labelError {
  display: flex;
}

.error {
  color: #ff0000;
}

.UploadHeader {
  display: flex;
  align-items: center;
  gap: 20px;
}

.avatarContainer {
  width: 64px;
  height: 64px;
}

.avatarImage, .avatarImageRect {
  width: 64px;
  height: 64px;
}

.avatarImage {
  border-radius: 64px;
}
