@import '../../assets/css/variables';
@import '../../assets/css/globals';

.container {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background: #F0F0F0;
}

.card {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid #E9E9E9;
    background: #FFFFFF;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.06), 0 0 4px 0 rgba(0, 0, 0, 0.04);

    @media (max-width: $small-screen) {
        width: 100%;
    }
    @media (min-width: $small-screen) {
        width: 588px;
    }
}

.cover{
    height: 150px;
    width: 100%;
    object-fit: cover;
}

.coverPhoto {
    display: flex;
    width: 100%;
    height: 150px;
    align-items: center;
    justify-content: center;
}

.profilePhotoWrapper {
    width: 130px;
    height: 130px;
    background: lightgray 50% / cover no-repeat;
    border-radius: 104px;
    margin-top: -65px;
    z-index: 10;
}

.noProfilePhotoWrapper {
    width: 100%;
    height: 35px;
    background-color: inherit;
}

.cardBodyWrapper {
    @media (max-width: $small-screen) {
        width: calc(100% - 64px);
    }
    @media (min-width: $small-screen) {
        width: calc(100% - 100px);
    }
}

.btn {
    display: flex;
    width: 100%;
    padding: 15px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 32px;
    background: #242222;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    border: none;
}

.btnMargin {
    margin: 16px 0 9px 0;
}

.textAbout {
    font-weight: 400;
    text-align: left;
}

.imageWrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.profilePhoto {
    width: 100%;
    height: 130px;
    border-radius: 130px
}

.aboutContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 80%;
    padding: 10px 0;
}

.title {
    font-size: 16px;
    font-weight: 600;
}

.poweredBy {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 30px 30px 16px 30px;
    justify-content: center;
}

.poweredByDisabled {
    margin: 24px;
}

.noMargins {
    margin: 0;
}

.poweredByText {
    font-size: 12px;
    font-weight: 600;
}

.infoContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    margin-top: 20px;
    width: -webkit-fill-available;
    padding: 0 10px;
    text-align: center;
}

.positionWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: -webkit-fill-available;
}

.fullname {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    width: 100%;
    max-width: 100%;
}

.position {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    width: 100%;
    max-width: 100%;
}

.company {
    background-color: #f0f0f0;
    border-radius: 12px;
    padding: 2px 12px;
    cursor: pointer;
    margin-top: 8px;
    font-size: 14px;
    text-align: center;
    max-width: 95%;
}

.position, .company, .fullname, .aboutContainer, .text {
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
}

.divider {
    height: 1px;
    width: 100%;
    background-color: #E9E9E9;
    align-items: center;
    margin: 16px 0 16px 0;
}

.emptyContent {
    text-align: center;
}

.emptyImg {
    margin-top: 20px;
}

.emptyText{
    font-weight: 600;
}

.footer, .footerActivateProfile {
    display: flex;
    flex-wrap: nowrap;
    gap: 8px;
    font-size: 12px;
    color: #464444;
}

.footer {
    padding: 8px 0;
}