@import '../../../assets/css/variables.scss';

.deleteBtn {
    height: auto;
    padding: 12px 24px 12px 24px;
    gap: 8px;
    border-radius: 6px;
    background: #FEF2F2;
    box-sizing: border-box;

    @media screen and (max-width: $small-screen) {
        max-width: 191px;
    }

    @media screen and (min-width: $small-screen) {
        width: 191px;
    }
}

.btnText {
    font-family: $font-family-lato;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #B91C1C;
}

.btnWrapper {
    @media screen and (max-width: $small-screen) {
        flex-direction: column-reverse;
    }
}