@import '../../../assets/css/variables.scss';

.navContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: black;
  color: white;
  height: 72px;
  padding: 0 40px;
}

.userInfo {
  display: flex;
  cursor: pointer;
  align-items: center;
  z-index: 2;

   .selector {
    width: fit-content;
   }
}

.username {
  margin-left: 7px;
  margin-bottom: 2px;
  max-width: 160px;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  overflow-x: hidden;

  @media screen and (max-width: $small-screen ) {
    display: none;
  }
}

.span {
  font-size: 14px !important;
  color: rgba(128, 128, 128, 1) !important;
}


.navContainer {
  @media screen and (max-width: $medium-screen ) {
    padding: 0 10px;
  }
}


.avatar {
  width: 28px;
  height: 28px;
}

.avatarImage {
  width: 28px;
  height: 28px;
  border-radius: 28px;
  background-color: #ffffff;
}

.homeHeader {
  margin-top: 84px;
}

.selectorContainer {
  padding: 16px;
  background-color: #FFFFFF;

  .selector {
    width: 100%;
  }
}


.separator {
  justify-content: flex-end;
  align-items: flex-end;
  width: 20px;
  height: 20px;
  color: #FFFFFF80;

  @media screen and (max-width: $small-screen ) {
    display: none;
  }
  @media screen and (min-width: $small-screen ) {
    display: flex;
  }
}

.logo {
  @media screen and (max-width: $small-screen ) {
    width: 96px !important;
    height: 30px !important;
  }
}

.drawerPaper {
  width: 85%;
}

.drawerContent {
  margin-top: 14px;
}

.mobileIcons {
  display: flex;
  align-items: center;
}

.profileSection {
  display: flex;
  align-items: center;
  gap: 12px;
}

.mobileAvatar {
  width: 40px;
  height: 40px;
}

.mobileAvatarImage {
  width: 40px;
  height: 40px;
  border-radius: 40px;
}

.name {
  font-size: 16px;
}

.email {
  font-size: 14px;
  color:#242222BF;
}

.profileSection {
  padding: 12px;
}