@import '../../../assets/css/variables';

.container {
    position: absolute;
    width: max-content;
    border-radius: 8px;
    background: #FFFFFF;
    z-index: 21;
    top: 36px;
    right: 8px;
    padding: 2px 16px 2px 16px;
    box-sizing: border-box;
    box-shadow: 0 0 0 1px #0000000D;
}

.item {
    display: flex;
    width: 100%;
    gap: 8px;
    padding: 11px 0 11px 0;
    box-sizing: border-box;
    justify-content: flex-start;
    align-items: center;
}

.pointer {
    cursor: pointer;
}

.iconWrapper {
    width: 24px;
    height: 24px;
}

.text {
    font-family: $font-family-lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #242222;
}