@import '../../../assets/css/variables.scss';

.saveBtnContainer {
    position: fixed;
    width: 100%;
    padding: 16px 24px 16px 24px;
    border: 1px solid #FFFFFF;
    bottom: 0;
    background-color: #FFFFFF;
    box-sizing: border-box;

    @media screen and (min-width: $small-screen) {
        display: none;
    }
}

.saveBtn {
    width: 100% !important;
    justify-content: center;
}