@import '../../../assets/css/variables.scss';

.divider {
    display: flex;
    width: 100%;
    border: 0.5px solid #E6E5E5;
}

.selector {
    width: 100%;
}

.languageSelector {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    margin-bottom: 16px;
}

.languageText {
    font-size: 15px;
    font-weight: 500;
    align-self: flex-start;
    color: #464444;
    line-height: 20px;
}

.passwordWrapper {
    display: flex;
    gap: 8px;

    @media screen and (max-width: $medium-screen) {
        flex-direction: column;
        gap: 16px;
    }
}

.changePwdBtn, .savePwdBtn, .twofaEnableBtn, .twofaDisableBtn {
    min-width: 100px;
    justify-content: center;
    @media screen and (max-width: $medium-screen) {
        padding: 6px !important;
        align-self: flex-start;
    }
}

.changePwdBtn, .twofaEnableBtn, .twofaDisableBtn {
    height: 48px;
    width: max-content !important;
}

.twofaEnableBtn, .twofaDisableBtn {
    min-width: 130px;
    @media screen and (max-width: $small-screen) {
        align-self: left;
    }
    @media screen and (min-width: $small-screen) {
        align-self: center;
    }
}

.twofaDisableBtn {
    background-color: #FEF2F2;
    border: 1px solid #FEF2F2;
}

.twofaDisableBtn div {
    color: #B91C1C;
}

.savePwdBtn {
    background-color: #242222 !important;
    height: 48px;
    width: max-content !important;
    flex: auto;
    justify-content: center;
    div {
        color: #FFFFFF
    }
}

.btnInactive {
    cursor: not-allowed !important;
    opacity: 0.5;
}

.input, .inputChangePwd {
    input {
        height: 48px;
        box-sizing: border-box;
    }
}

.grayInput {
    input {
        height: 48px;
        box-sizing: border-box;
        background: #F0F0F0;
        max-width: unset;
    }
}

.imageWrapper {
    display: flex;
    gap: 20px;
}

.noImage {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $font-family-lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #464444;
}

.twoFactorAuth {
    display: flex;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #D9D9D9;
    justify-content: space-between;
    @media screen and (max-width: $small-screen) {
        flex-direction: column;
        gap: 8px;
    }
    @media screen and (min-width: $small-screen) {
        flex-direction: row;
    }
}

.twofaTextWrapper {
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 6px;
    @media screen and (max-width: $small-screen) {
        margin-right: 8px;
    }
    @media screen and (min-width: $small-screen) {
        margin-right: 20%;
    }
}

.twofaTitle, .twofaDesc {
    font-family: $font-family-lato;
    font-weight: 500;
}

.twofaTitle {
    font-size: 20px;
    line-height: 28px;
}

.twofaDesc {
    font-size: 15px;
    line-height: 20px;
}

.enabled {
    width: fit-content;
    padding: 2px 12px 2px 12px;
    border-radius: 12px;
    background-color: #D1FAE5;
    color: #065F46;
    font-family: $font-family-lato;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
}