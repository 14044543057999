.container {
    width: 384px;
    background-color: #FFFFFF;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-top: 24px;
}

.countContainer {
    background-color: #F8F8F8;
    padding: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

.title {
    font-size: 22px;
    font-weight: 700;
    padding: 0 24px;
}

.icon {
    padding: 0 24px;
}

.count {
    cursor: pointer;
    color: #2563EB;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
}

.arrow {
    cursor: pointer;
}