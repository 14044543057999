@import '../../../assets/css/variables';

.container {

}

.input {
    height: 48px;
    padding: 12px 13px 12px 48px;
    border-radius: 6px;
    border: 1px solid #CDCDCD;
    background-image: url("../../../assets/icons/search.svg");
    background-repeat: no-repeat;
    background-position-x: 12px;
    background-position-y: center;
    box-sizing: border-box;
    outline: none;

    @media screen and (max-width: $large-screen) {
        width: 144px;
    }
    @media screen and (min-width: $large-screen) {
        width: 288px;
    }
}

.input::placeholder {
    font-family: $font-family-lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    top: 10px;
}