@import '../../../assets/css/variables.scss';

.headerContainer {
    display: flex;
    flex-direction: column;
}

.navContainer {
    display: flex;
    justify-content: space-between;
    background-color: white;
    padding: 0 80px;
    min-height: 71px;

    @media screen and (max-width: $medium-screen) {
        padding: 0 20px;
    }
}

.navMobileContainer {
    display: flex;
    background-color: white;
    justify-content: center;
    gap: 24px;
    border-top: 1px solid #DCDBDD;
    @media screen and (min-width: $small-screen) {
        display: none;
    }
}

.sticky {
    position: fixed;
    top: 0;
    width: -webkit-fill-available;
    z-index: 11;
}

.navLinks {
    display: flex;
    gap: 22px;
}

.leftLinks {
    display: flex;
    align-items: center;
    gap: 22px;
}

.link {
    text-decoration: none;
    color: #1A1523;
}

.activeLink {
    text-decoration: none;
    color: #1A1523;
    border-bottom: 4px solid red;
    padding: 24px 0
}

.rightLinks {
    display: flex;
    align-items: center;
    gap: 22px;
}

.link {
    text-decoration: none;
    color: #1A1523;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

.return {
    display: flex;
    align-items: center;
    gap: 8px;
}

.dotsIcon {
    cursor: pointer;
}

.menuActive {
    width: 42px;
    height: 42px;
    background-color: #F0F0F0;
    border-radius: 50%;
}

.item {
    display: flex;
    width: 100%;
    gap: 8px;
    padding: 11px 0 11px 0;
    box-sizing: border-box;
    justify-content: flex-start;
    align-items: center;
}

.iconWrapper {
    width: 24px;
    height: 24px;
}

.inactiveWrapper {
    flex-direction: column;
    gap: 0 !important;
    padding-top: 4px;
}

.inactive {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
}

.pointer {
    cursor: pointer;
}

.switchWrapper {
    margin-left: auto;
}

.text {
    font-family: $font-family-lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
}

.deleteText {
    color: #B91C1C
}

.subText {
    font-family: $font-family-lato;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    margin: 0 38px 0 32px;
    letter-spacing: 0;
    color: #808080;
}

.popoverStyle {
    width: 311px;
    padding: 5px 18px;
    border-radius: 8px;
}

.avatarImage {
    width: 40px;
    height: 40px;
    border-radius: 28px;
    background-color: #ffffff;
}

.hideMobile {
    @media screen and (max-width: $small-screen) {
        display: none;
    }
}

.hideLargeScreen {
    @media screen and (max-width: $large-screen) {
        display: none;
    }
}