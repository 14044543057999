@import '../../assets/css/variables.scss';

.container {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    z-index: 1001;
    background-color: #00000040;
}

.modal {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 340px;
    height: 400px;
    border-radius: 12px;
    gap: 32px;
    box-shadow: 0 10px 10px -5px #0000000A;
    background-color: #FFFFFF;
    box-sizing: border-box;

    @media screen and (max-width: $x-small-screen) {
        margin: 8px;
    }

    @media screen and (min-width: $x-small-screen) {
        margin: 16px;
    }

}