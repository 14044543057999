@import "../../../assets/css/variables.scss";

.container {
    display: flex;
    justify-content: space-between;
    height: 88px;
}

.sides {
    display: flex;
    align-items: center;
    gap: 15px;
}

.title {
    font-family: $font-family-lato;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    color: #111827;
}

.icons {
    cursor: pointer;
}

.editIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
}

.toggle {
    cursor: pointer;
}

.unsavedTitle {
    color: #1D4ED8;
}