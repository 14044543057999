@import '../../../assets/css/variables.scss';

.imageBox {
    align-self: flex-start;
}

.image {
    @media screen and (max-width: $large-screen) {
        width: 40px;
        height: 40px;
        border-radius: 40px;
    }

    @media screen and (min-width: $large-screen) {
        width: 48px;
        height: 48px;
        border-radius: 48px;
    }
}

.container {
    flex-direction: row !important;

    @media screen and (max-width: $large-screen) {
        padding: 16px !important;
    }

    @media screen and (min-width: $large-screen) {
        padding: 20px !important;
    }
}

.containerWrapper {
    display: flex;
    width: 95%;
    height: auto;
    justify-content: space-between;
    text-align: center;
    min-width: 0;

    @media screen and (max-width: $large-screen) {
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
    }

    @media screen and (min-width: $large-screen) {
        align-items: center;
        gap: 8px;
    }
}

.text {
    font-family: $font-family-lato;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    word-break: break-word;
}

.textStatusAccepted, .textStatusPending, .textStatusExpired {
    display: flex;
    width: fit-content;
    box-sizing: border-box;
    height: 21px;
    justify-content: center;
    align-items: center;
    font-family: $font-family-lato;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: start;
    padding: 6px 10px 6px 10px;
    gap: 6px;
    border-radius: 10px;
    background: #FEF3C7;
    margin-top: 6px;
}

.textStatusAccepted {
    background-color: #D1FAE5;
    color: #065F46;
}

.textStatusPending {
    background-color: #FEF3C7;
    color: #92400E;
}

.textStatusExpired {
    background-color: #FEE2E2;
    color: #991B1B;
}

.textTitle {
    font-weight: 600;
    color: #111827;
}

.textSmall {
    font-weight: 400;
    color: #6B7280;
}

.textNormal {
    font-weight: 500;
    color: #242222;
}

.userContainer {
    display: flex;
    gap: 16px;
    justify-content: left;
    align-items: center;
    flex: 1;
}

.nameContainer {
    display: flex;
    flex-direction: column;
}

.roleContainer {
    display: flex;
    gap: 4px;

    @media screen and (max-width: $large-screen) {
        width: 100%;
    }

    @media screen and (min-width: $large-screen) {
        width: 18%;
    }
}

.titleContainer {
    @media screen and (max-width: $large-screen) {
        width: 100%;
    }

    @media screen and (min-width: $large-screen) {
        width: 30%;
    }
}

.menuContainer {
    display: flex;
    margin-left: auto;
    width: 32px;

    @media screen and (max-width: $large-screen) {
        align-self: flex-start;
    }
}

.menuActive {
    width: 32px;
    height: 32px;
    background-color: #F0F0F0;
    border-radius: 50%;
}

.noMenu {
    width: 32px;
    height: 32px;
}

.dotsIcon {
    cursor: pointer;
    margin-left: auto;
    width: 32px;
    height: 32px;
}

.selector {
    width: fit-content !important;
}

