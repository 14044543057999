@import '../../assets/css/variables.scss';

.container {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    padding: 32px;
    background-color: #FFFFFF;
    border: 1px solid #E5E7EB;
}

.titleWrapper {
    display: flex;
    gap: 8px;
    flex-direction: column;
    margin-bottom: 24px;
}

.title {
    font-family: $font-family-lato;
    font-weight: 700;
    line-height: 28px;
    color: #242222;
    @media screen and (max-width: $medium-screen) {
        font-size: 20px;
    }
    @media screen and (min-width: $medium-screen) {
        font-size: 24px;
    }
}

.date {
    font-family: $font-family-lato;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    color: #24222280;
}

.summary {
    font-family: $font-family-lato;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #242222;
    margin-bottom: 24px;
}

.images {
    display: flex;
    gap: 8px;
    margin-bottom: 24px;
    flex-wrap: wrap;
}

.img {
    max-width: -webkit-fill-available;
}