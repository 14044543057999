@import '../../../assets/css/variables.scss';

.salesforcePopup {
    display: flex;
    gap: 16px;
    justify-content: center;
    align-items: center;
    background-color: #3B82F6;
    font-family: $font-family-lato;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    padding: 8px 16px 8px 16px;
}

.salesforceWrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: $medium-screen) {
        flex-direction: column;
    }
    @media screen and (min-width: $medium-screen) {
        gap: 4px;
    }
}

.salesforceConnect {
    text-decoration: underline;
    text-decoration-color: #FFFFFF;
    cursor: pointer;
}
