@import '../../assets/css/variables';

.contactWidget {
    position: fixed;
    bottom: 0;
    right: 0;
    cursor: pointer;
}

.container {
    position: fixed;
    bottom: 16px;
    right: 16px;
    z-index: 101;
    border-radius: 16px;
    background-color: #F6F6F6;
    box-shadow: 0 8px 16px 0 #00000014;
    padding: 24px;

    @media screen and (max-width: $small-screen) {
        width: auto;
    }
    @media screen and (min-width: $small-screen) {
        width: 376px;
    }
}

.header {
    display: flex;
    flex-direction: row;
    gap: 16px;
    margin-bottom: 24px;
}

.headerText {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $font-family-lato;
    font-size: 32px;
    font-weight: 600;
    line-height: 38.4px;
    text-align: left;
}

.logo {
    display: flex;
    width: 60px;
    height: 60px;
    background-color: #FFFFFF;
    border-radius: 60px;
    justify-content: center;
    align-items: center;
}

.x {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: flex-start;
}

.description {
    font-family: $font-family-lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    margin-bottom: 56px;
}

.contactContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
}

.contact {
    display: flex;
    width: 100%;
    padding: 16px;
    gap: 20px;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-sizing: border-box;
    cursor: pointer;
}

.contactTextWrapper {
    display: flex;
    flex-direction: column;
}

.contactTextTitle {
    font-family: $font-family-lato;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;

}

.contactTextDesc {
    font-family: $font-family-lato;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    color: #242222;
}

.close {
    stroke: #141414;
    width: 17.5px;
    height: 17.5px;
    cursor: pointer;
}

.footer {
    display: flex;
    width: 100%;
    margin-top: 40px;
    justify-content: center;
    align-items: center;
}

.xText {
    width: fit-content;
    font-family: $font-family-lato;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    cursor: pointer;
    padding: 8px;
}

.contactActionIcon {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
}

