$main-background-color: #fff;
$ic-empty-color: #ABABAB;
$ic-fill-color: #ED1B24;

$ic-width: 127px;
$ic-height: 141px;

$ic-size: 1;
$ic-time: 1.5s;

.ic-logo {
    position: relative;

    overflow: hidden;
    width: $ic-width - 2px;
    height: $ic-height - 2px;
    margin: 0 auto;

    background: $ic-empty-color;

    transform: scale($ic-size);
}

.ic-logo__icon {
    position: absolute;
    top: -1px;
    left: -1px;
    z-index: 1;

    width: $ic-width;
    height: $ic-height;

    fill: $main-background-color;
}

.ic-logo__fill {
    width: $ic-width;
    height: $ic-height;

    background: $ic-fill-color;

    animation: fillUp $ic-time ease-in-out infinite;
}

@keyframes fillUp {
    0% {
        transform: translateX(-$ic-width); // Start from the left
    }
    100% {
        transform: translateX($ic-width); // Move to the right
    }
}

.x-reset-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background-color: #FFFFFF;
}